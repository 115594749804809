/* main.js */
// Initialize some libraries
window.$ = window.jQuery = require('jquery');

var slick = require('slick-carousel');

var fitvids = require('fitvids');

var matchHeight = require('jquery-match-height');

var modal = require('jquery-modal');

var hoverIntent = require('jquery-hoverintent');

var cookie = require('jquery.cookie');

function PopoverEngine() {
  this.popovers = $('.popover-view');
  this.controls = $('.popover-toggle');
  this.init();
}

PopoverEngine.INITIALIZED_CLASSNAME = 'popover-initialized';
PopoverEngine.VISIBLE_CLASSNAME = 'visible-state';
PopoverEngine.HIDDEN_CLASSNAME = 'hidden-state';
PopoverEngine.prototype = {
  init: function init() {
    var engine = this;
    this.popovers.each(function (i, popover) {
      engine.registerElement(popover);
    });
    this.controls.each(function (i, control) {
      engine.registerControl(control);
    });
    $(document).on('mouseup touchend', function (event) {
      var clickedOutside = !engine.popovers.is(event.target);
      var clickedInside = engine.popovers.has(event.target).length === 1;

      if (clickedOutside && !clickedInside) {
        engine.hide(engine.popovers);
      }
    });
    $('body').append('<div class="popover-dimming-cell"></div>');
    this.dimmingCell = $('.popover-dimming-cell');
    this.dimmingCell.hide();
  },
  registerElement: function registerElement(popover) {
    $(popover).remove().appendTo(document.body).addClass(PopoverEngine.INITIALIZED_CLASSNAME).addClass(PopoverEngine.HIDDEN_CLASSNAME);
  },
  registerControl: function registerControl(control) {
    var engine = this;
    control = $(control);
    control.bind('click', function (event) {
      event.preventDefault();
      var id = control.attr('data-for');
      var target = $('#' + id);

      if (target.length) {
        if (target.hasClass(PopoverEngine.HIDDEN_CLASSNAME)) {
          engine.show(target, event.pageX, event.pageY);
        } else {
          engine.hide(target);
        }
      }
    });
  },
  show: function show(popover, atX, atY) {
    popover = $(popover);
    atX = Math.max(0, atX - 30);
    atY = Math.max(0, atY - 15);
    popover.removeClass(PopoverEngine.HIDDEN_CLASSNAME).addClass(PopoverEngine.VISIBLE_CLASSNAME).css({
      left: atX,
      top: atY
    });
    this.dimmingCell.show();
  },
  showAtIndex: function showAtIndex(index) {},
  hide: function hide(popover) {
    $(popover).removeClass(PopoverEngine.VISIBLE_CLASSNAME).addClass(PopoverEngine.HIDDEN_CLASSNAME);
    this.dimmingCell.hide();
  },
  hideAtIndex: function hideAtIndex(index) {}
};

var forceRedraw = function forceRedraw(element) {
  if (!element) {
    return;
  }

  var n = document.createTextNode(' ');
  var disp = element.style.display; // don't worry about previous display style

  element.appendChild(n);
  element.style.display = 'none';
  setTimeout(function () {
    element.style.display = disp;
    n.parentNode.removeChild(n);
  }, 20); // you can play with this timeout to make it as short as possible
};

function getTimeRemaining(endtime) {
  var t = Date.parse(endtime) - Date.parse(new Date());
  var seconds = Math.floor(t / 1000 % 60);
  var minutes = Math.floor(t / 1000 / 60 % 60);
  var hours = Math.floor(t / (1000 * 60 * 60) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(id, endtime) {
  var clock = document.getElementById(id);

  if (!clock) {
    return;
  }

  var daysSpan = clock.querySelector('.days');
  var hoursSpan = clock.querySelector('.hours');
  var minutesSpan = clock.querySelector('.minutes');
  var secondsSpan = clock.querySelector('.seconds');

  function updateClock() {
    var t = getTimeRemaining(endtime);
    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
      clock.style.display = 'none';
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock, 1000);
}

;

(function (window) {
  'use strict';
  /**
   * based on from https://github.com/inuyaksa/jquery.nicescroll/blob/master/jquery.nicescroll.js
   */

  function hasParent(e, p) {
    if (!e) return false;
    var el = e.target || e.srcElement || e || false;

    while (el && el != p) {
      el = el.parentNode || false;
    }

    return el !== false;
  }

  ;
  /**
   * extend obj function
   */

  function extend(a, b) {
    for (var key in b) {
      if (b.hasOwnProperty(key)) {
        a[key] = b[key];
      }
    }

    return a;
  }
  /**
   * SelectFx function
   */


  function SelectFx(el, options) {
    this.el = el;
    this.options = extend({}, this.options);
    extend(this.options, options);

    this._init();
  }
  /**
   * SelectFx options
   */


  SelectFx.prototype.options = {
    // if true all the links will open in a new tab.
    // if we want to be redirected when we click an option, we need to define a data-link attr on the option of the native select element
    newTab: true,
    // when opening the select element, the default placeholder (if any) is shown
    stickyPlaceholder: true,
    // callback when changing the value
    onChange: function onChange(val) {
      return false;
    }
  };
  /**
   * init function
   * initialize and cache some vars
   */

  SelectFx.prototype._init = function () {
    // check if we are using a placeholder for the native select box
    // we assume the placeholder is disabled and selected by default
    var selectedOpt = this.el.options[this.el.selectedIndex];
    this.hasDefaultPlaceholder = selectedOpt && selectedOpt.disabled; // get selected option (either the first option with attr selected or just the first option)

    this.selectedOpt = selectedOpt || this.el.querySelector('option'); // create structure

    this._createSelectEl(); // all options


    this.selOpts = [].slice.call(this.selEl.querySelectorAll('li[data-option]')); // total options

    this.selOptsCount = this.selOpts.length; // current index

    this.current = this.selOpts.indexOf(this.selEl.querySelector('li.cs-selected')) || -1; // placeholder elem

    this.selPlaceholder = this.selEl.querySelector('span.cs-placeholder'); // init events

    this._initEvents();
  };
  /**
   * creates the structure for the select element
   */


  SelectFx.prototype._createSelectEl = function () {
    var self = this,
        options = '',
        createOptionHTML = function createOptionHTML(el) {
      var optclass = '',
          classes = '',
          link = '';

      if (el.selectedOpt && !this.foundSelected && !this.hasDefaultPlaceholder) {
        classes += 'cs-selected ';
        this.foundSelected = true;
      } // extra classes


      if (el.getAttribute('data-class')) {
        classes += el.getAttribute('data-class');
      } // link options


      if (el.getAttribute('data-link')) {
        link = 'data-link=' + el.getAttribute('data-link');
      }

      if (classes !== '') {
        optclass = 'class="' + classes + '" ';
      }

      var extraAttributes = '';
      [].forEach.call(el.attributes, function (attr) {
        var name = attr['name'];

        if (name.indexOf('data-') + ['data-option', 'data-value'].indexOf(name) == -1) {
          extraAttributes += name + "='" + attr['value'] + "' ";
        }
      });
      return '<li ' + optclass + link + extraAttributes + ' data-option data-value="' + el.value + '"><span>' + el.textContent + '</span></li>';
    };

    [].slice.call(this.el.children).forEach(function (el) {
      if (el.disabled) {
        return;
      }

      var tag = el.tagName.toLowerCase();

      if (tag === 'option') {
        options += createOptionHTML(el);
      } else if (tag === 'optgroup') {
        options += '<li class="cs-optgroup"><span>' + el.label + '</span><ul>';
        [].slice.call(el.children).forEach(function (opt) {
          options += createOptionHTML(opt);
        });
        options += '</ul></li>';
      }
    });
    var opts_el = '<div class="cs-options"><ul>' + options + '</ul></div>';
    this.selEl = document.createElement('div');
    this.selEl.className = this.el.className;
    this.selEl.tabIndex = this.el.tabIndex;
    this.selEl.innerHTML = '<span class="cs-placeholder">' + this.selectedOpt.textContent + '</span>' + opts_el;
    this.el.parentNode.appendChild(this.selEl);
    this.selEl.appendChild(this.el);
  };
  /**
   * initialize the events
   */


  SelectFx.prototype._initEvents = function () {
    var self = this; // open/close select

    this.selPlaceholder.addEventListener('click', function () {
      self._toggleSelect();
    }); // clicking the options

    this.selOpts.forEach(function (opt, idx) {
      opt.addEventListener('click', function () {
        self.current = idx;

        self._changeOption(); // close select elem


        self._toggleSelect();
      });
    }); // close the select element if the target it´s not the select element or one of its descendants..

    document.addEventListener('click', function (ev) {
      var target = ev.target;

      if (self._isOpen() && target !== self.selEl && !hasParent(target, self.selEl)) {
        self._toggleSelect();
      }
    });
  };
  /**
   * open/close select
   * when opened show the default placeholder if any
   */


  SelectFx.prototype._toggleSelect = function () {
    // remove focus class if any..
    this._removeFocus();

    if (this._isOpen()) {
      if (this.current !== -1) {
        // update placeholder text
        this.selPlaceholder.textContent = this.selOpts[this.current].textContent;
      }

      classie.remove(this.selEl, 'cs-active');
    } else {
      if (this.hasDefaultPlaceholder && this.options.stickyPlaceholder) {
        // everytime we open we wanna see the default placeholder text
        this.selPlaceholder.textContent = this.selectedOpt.textContent;
      }

      classie.add(this.selEl, 'cs-active');
    }
  };
  /**
   * change option - the new value is set
   */


  SelectFx.prototype._changeOption = function () {
    // current option
    var opt = this.selOpts[this.current]; // update current selected value

    this.selPlaceholder.textContent = opt.textContent; // change native select element´s value

    this.el.value = opt.getAttribute('data-value'); // remove class cs-selected from old selected option and add it to current selected option

    var oldOpt = this.selEl.querySelector('li.cs-selected');

    if (oldOpt) {
      classie.remove(oldOpt, 'cs-selected');
    }

    classie.add(opt, 'cs-selected'); // if there´s a link defined

    if (opt.getAttribute('data-link')) {
      // open in new tab?
      if (this.options.newTab) {
        window.open(opt.getAttribute('data-link'), '_blank');
      } else {
        window.location = opt.getAttribute('data-link');
      }
    } // callback


    this.options.onChange(this.el.value);
  };
  /**
   * returns true if select element is opened
   */


  SelectFx.prototype._isOpen = function (opt) {
    return classie.has(this.selEl, 'cs-active');
  };
  /**
   * removes the focus class from the option
   */


  SelectFx.prototype._removeFocus = function (opt) {
    var focusEl = this.selEl.querySelector('li.cs-focus');

    if (focusEl) {
      classie.remove(focusEl, 'cs-focus');
    }
  };
  /**
   * add to global namespace
   */


  window.SelectFx = SelectFx;
})(window);

window.addEventListener('DOMContentLoaded', function (event) {
  // Popovers
  var engine = new PopoverEngine(); // Sliders

  $('.faq-slider').slick({
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    setPosition: 0
  });
  $('.pagination-group').not('.slick-initialized').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 15000,
    adaptiveHeight: true
  });
  $('.auto-rotate').not('.slick-initialized').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000
  });
  $('.home-history-layout ._promotions').not('slick-initialized').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        centerMode: true,
        centerPadding: '40px'
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '40px'
      }
    }]
  });
  $('.nyrainc-home-block ._promotions').not('slick-initialized').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        centerMode: true,
        centerPadding: '40px'
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '40px'
      }
    }]
  });
  var totebar = $('.race-ribbon-slider');
  var totebarAuxiliary = $('<div class="race-ribbon-auxiliary"></div>');
  $('.race-ribbon-item-content', totebar).each(function (i, el) {
    $(el).remove().appendTo(totebarAuxiliary);
  });
  totebarAuxiliary.appendTo(document.body);
  totebar.on('init', function (slick) {
    $('.race-ribbon').addClass('loaded');
    $('body').addClass('race-ribbon-loaded');
    $('.race-ribbon-item', totebar).each(function (i, el) {
      var hideRequest;
      var hideThreshold = 32; // ~30fps

      var auxiliaryContent = $('.race-ribbon-item-content', totebarAuxiliary).eq(i);
      $(el).on('mouseenter', function (event) {
        var rect = el.getBoundingClientRect();
        clearTimeout(hideRequest);
        $(el).addClass('active-state');
        auxiliaryContent.css({
          left: rect.left,
          top: rect.top + rect.height + window.scrollY,
          width: rect.width,
          height: 'auto',
          display: 'block'
        });
      }).on('mouseleave', function (event) {
        hideRequest = setTimeout(function () {
          hideDetails();
        }, hideThreshold);
      });
      auxiliaryContent.on('mouseenter', function (event) {
        clearTimeout(hideRequest);
      }).on('mouseleave', function (event) {
        hideRequest = setTimeout(function () {
          hideDetails();
        }, hideThreshold);
      });

      function hideDetails() {
        $(el).removeClass('active-state');
        auxiliaryContent.css({
          width: 0,
          height: 0,
          display: 'none'
        });
      }
    });
  }).slick({
    infinite: false,
    cssEase: 'ease-in-out',
    pauseOnHover: false,
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    draggable: false,
    responsive: [{
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }, {
      breakpoint: 850,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
  var $carousel = $('.pagination-group');
  $(document).on('keydown', function (e) {
    if (e.keyCode == 37) {
      $carousel.slick('slickPrev');
    }

    if (e.keyCode == 39) {
      $carousel.slick('slickNext');
    }
  });
  $('.pagination-group').on('mousemove', function (e) {
    var screenWidth = $(window).width();
    var mousePos = e.clientX / screenWidth;

    if (mousePos < 0.15) {
      $('.slick-prev').addClass('hovering');
    } else if (mousePos > 0.85) {
      $('.slick-next').addClass('hovering');
    } else {
      $('.slick-next,.slick-prev').removeClass('hovering');
    }
  });

  function homeSlideDidLoad(slide) {
    var videoQuery = $('video', slide);

    if (videoQuery.length) {
      var video = videoQuery.get(0);
      video.play();
      forceRedraw($('._content', slide).get(0)); // Force repaint
    }
  }

  var homeCarouselQuery = $('.home-content .pagination-group');
  homeCarouselQuery.on('afterChange', function (event, slick, slide) {
    homeSlideDidLoad(slick.$slides[slide]);
    $('iframe').each(function () {
      $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'mute' + '","args":""}', '*');
    });
  }); // Did load first slide

  homeSlideDidLoad($('> *', homeCarouselQuery).eq(0)); // Source a random background image from elements with a data-bg-sources attribute

  var $randomBackgroundQuery = $('[data-bg-sources]');
  $randomBackgroundQuery.each(function (i, el) {
    var sources = $(el).attr('data-bg-sources').split(' |');
    var randomIndex = Math.floor(Math.random() * (sources.length - 1));
    $(el).css('background-image', 'url(' + sources[randomIndex] + ')');
  }); // Countdown

  var deadline = new Date(Date.parse('2017-07-21T12:00:00'));
  initializeClock('longines-clock', deadline);
  $('.load-more').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    var page = $this.data('current-page') ? $this.data('current-page') + 1 : 1;
    var parent = $this.data('parent');
    var offset = $this.data('offset');
    var limit = $this.data('limit');
    var spinner = $('.spinner');
    var actionButton = $this.find('.action-button');

    if (actionButton.length && spinner.length) {
      actionButton.hide();
      spinner.show();
    }

    $.ajax({
      type: "GET",
      url: "/pagination-processor",
      data: {
        page: page,
        parents: parent,
        data_offset: offset,
        data_limit: limit,
        filters: $this.data('tvfilters')
      },
      success: function success(response) {
        parsedResponse = $.parseJSON(response);

        if (parsedResponse.success) {
          $("#additional-stories").append(parsedResponse.page);
          $this.data('current-page', page);
          var articleCount = (parsedResponse.page.match(/<article/g) || []).length;

          if (limit && articleCount < limit || !limit && articleCount < 6) {
            $this.remove();
          }
        } else {
          $this.after(parsedResponse.page);
          $this.remove();
        }
      },
      error: function error(jqXHR, textStatus, errorThrown) {
        console.log("Error retrieving articles :", textStatus, jqXHR);
      },
      complete: function complete() {
        if (actionButton.length && spinner.length) {
          actionButton.show();
          spinner.hide();
        }
      }
    });
  }); // Connection Profiles - NYRAInc Media Center

  if ($('[data-controller="ConnectionProfile"]').length) {
    var removeHash = function removeHash() {
      history.replaceState('', document.title, window.location.pathname + window.location.search);
    };

    $('[data-controller="ConnectionProfile"]').each(function (index, element) {
      var contentElement = $('[data-controller="ConnectionProfileContent"]', element);
      var menuElement = $('[data-controller="ConnectionProfileMenu"]', element);
      var headingQuery = $('h2', contentElement);
      headingQuery.each(function (headingIndex, heading) {
        var $heading = $(heading);
        var id = 'content' + index + 'heading' + headingIndex;
        var text = $heading.text();
        var $link = $('<a href="#' + id + '">' + text + '</a>').on('click', function (event) {
          event.preventDefault();
          location.hash = id;
        });
        var $item = $('<div></div>').append($link);
        $heading.attr('id', id);
        menuElement.append($item);
      });
    }); //Jump to header after ID's are added for deep linking

    location.hash = location.hash; //Remove hash form url if is there is no deep linking

    if (location.hash.length < 2) {
      removeHash();
    }
  } // Modal Interruptions


  var modal_content_query = $(".campaign-modal-outlet").eq(0);

  if ('modal' in $.fn && modal_content_query.length) {
    var modal_session_id = modal_content_query.attr('data-campaign-id');
    var modal_delay = parseInt(modal_content_query.attr('data-modal-delay'));

    if ($.cookie('modal_session_id') != modal_session_id) {
      modal_delay = Math.max(0, modal_delay);
      setTimeout(function () {
        modal_content_query.modal({
          showClose: true,
          fadeDuration: 600
        });
        $.cookie('modal_session_id', modal_session_id);
      }, modal_delay);
    }

    $('body').on('click', 'a[rel="modal:close"]', function (event) {
      $.modal.close();
      event.preventDefault();
    }); // Enable the condition below to test cookie tracking. 'OK' keeps cookie. 'Cancel' to reset.
    // else if ( ! confirm('You have already seen '+ modal_session_id)) {
    //   $.cookie('modal_session_id', '');
    // }
  }
}); // Additional page init

$(function () {
  // Select Box Dropdown
  (function () {
    [].slice.call(document.querySelectorAll('select.cs-select')).forEach(function (el) {
      new SelectFx(el);
    });
  })();
});
$(function () {
  var emailPrefFormSelectAll = $(".email-preferences-form .track-col .all-label + input");
  emailPrefFormSelectAll.on('click', function () {
    var col = $(this).parents('.track-col');
    var boxes = col.find('input');
    var allChecked = this.checked;
    boxes.each(function () {
      this.checked = allChecked;
    });
  });
});
$(function () {
  if ($('#scroll-top').length) {
    var scrollTrigger = 150,
        backToTop = function backToTop() {
      var scrollTop = $(window).scrollTop();

      if (scrollTop > scrollTrigger) {
        $('#scroll-top').addClass('show');
      } else {
        $('#scroll-top').removeClass('show');
      }
    };

    backToTop();
    $(window).on('scroll', function () {
      backToTop();
    });
    $('#scroll-top').on('click', function (e) {
      e.preventDefault();
      $('html,body').animate({
        scrollTop: 0
      }, 700);
    });
  }
});
$(function () {
  var mobileApp = getParameterByName('app') === 'true';

  if (mobileApp) {
    document.querySelectorAll('a').forEach(function (el) {
      // Determine the separator necessary by checking
      // if there's already a query string (search) parameter.
      var separator = el.search ? '&' : '?'; // Add the 'app' query parameter directly to the element.

      el.search = el.search.concat(separator, 'app=true'); // When in the app context, hide all wagering links.
      // TODO: This really should be in its own handler.

      if (el.innerHTML.toLowerCase().includes('bet now') || el.href.includes('nyrabets')) {
        el.remove();
      }
    });
  }
});
$(function () {
  $('[data-accordion-trigger]').click(function (e) {
    e.preventDefault();
    var parent = $(this).parents('[data-accordion]');

    if (parent) {
      var content = parent.find('[data-accordion-content]');

      if (content) {
        parent.hasClass("-open") ? parent.removeClass("-open") : parent.addClass("-open");
        content.slideToggle(100);
      }
    }
  });
}); // Utility Function to get URL Params

function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }

  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
} // https://stackoverflow.com/a/4656873/6132016


function getUrlVars() {
  var vars = [],
      hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }

  return vars;
}

$(document).ready(function () {
  // Adds responsiveness via fitvids plugin for all youtube videos https://github.com/davatron5000/FitVids.js 
  var src = $('iframe[src*="youtube"]').parent();
  fitvids(src); //Simple accordion 

  var allPanels = $('.accordion > dd').hide();
  var allTriggers = $('.accordion > dt');
  $('.accordion > dt').click(function () {
    $this = $(this);
    $target = $this.next();

    if (!$target.hasClass('active')) {
      allPanels.removeClass('active').slideUp(150);
      allTriggers.removeClass('open');
      $this.addClass('open');
      $target.addClass('active').slideDown(150);
    }

    return false;
  }); //Simple Tabs

  $('.tabgroup > div').addClass("hidden-tab");
  $('.tabgroup > div:first-of-type').removeClass("hidden-tab");
  $('.tabs a').click(function (e) {
    e.preventDefault();
    var $this = $(this),
        //in case you need more than one tab group. use id="first-tab-group" etc
    tabgroup = '#' + $this.parents('.tabs').data('tabgroup'),
        others = $this.closest('li').siblings().children('a'),
        target = $this.attr('href');
    others.removeClass('active');
    $this.addClass('active');
    $(tabgroup).children('div').addClass("hidden-tab");
    $(target).removeClass("hidden-tab");
  });
}); //end doc ready

$(function () {
  var raceItemsSelector = '.race-number-navigation';
  var raceMenuSelector = '.race-number-navigation-menu';
  var raceItems = $(raceItemsSelector).slick({
    'infinite': false,
    'dots': false,
    'arrows': false,
    'asNavFor': raceMenuSelector,
    'adaptiveHeight': true
  });
  var raceMenu = $('.race-number-navigation-menu').slick({
    'infinite': false,
    'dots': false,
    'arrows': false,
    'centerMode': true,
    'asNavFor': raceItemsSelector,
    'variableWidth': true,
    'swipeToSlide': true,
    'focusOnSelect': true
  });
  var raceDayNavigationMenu = $('.race-day-navigation-menu');
  var raceDayNavigationTitle = $('.race-day-navigation-title');
  raceDayNavigationTitle.bind('click', function onRaceDayNavigationTitleClick(event) {
    event.preventDefault();
    raceDayNavigationMenu.toggle();
    $('[data-outlet="title.value"]', raceDayNavigationTitle).toggle();
    $('[data-outlet="title.prompt"]', raceDayNavigationTitle).toggle();
  }); // Invalidate slide layout height

  $('a.replay-stream').on('click', function () {
    setTimeout(function () {
      raceItems.find(".slick-slide").height("auto");
      raceItems.slick("setOption", '', '', true);
    }, 500);
  });
});
;

(function (window) {
  'use strict';
  /**
   * based on from https://github.com/inuyaksa/jquery.nicescroll/blob/master/jquery.nicescroll.js
   */

  function hasParent(e, p) {
    if (!e) return false;
    var el = e.target || e.srcElement || e || false;

    while (el && el != p) {
      el = el.parentNode || false;
    }

    return el !== false;
  }

  ;
  /**
   * extend obj function
   */

  function extend(a, b) {
    for (var key in b) {
      if (b.hasOwnProperty(key)) {
        a[key] = b[key];
      }
    }

    return a;
  }
  /**
   * SelectFx function
   */


  function SelectFx(el, options) {
    this.el = el;
    this.options = extend({}, this.options);
    extend(this.options, options);

    this._init();
  }
  /**
   * SelectFx options
   */


  SelectFx.prototype.options = {
    // if true all the links will open in a new tab.
    // if we want to be redirected when we click an option, we need to define a data-link attr on the option of the native select element
    newTab: true,
    // when opening the select element, the default placeholder (if any) is shown
    stickyPlaceholder: true,
    // callback when changing the value
    onChange: function onChange(val) {
      return false;
    }
  };
  /**
   * init function
   * initialize and cache some vars
   */

  SelectFx.prototype._init = function () {
    // check if we are using a placeholder for the native select box
    // we assume the placeholder is disabled and selected by default
    var selectedOpt = this.el.options[this.el.selectedIndex];
    this.hasDefaultPlaceholder = selectedOpt && selectedOpt.disabled; // get selected option (either the first option with attr selected or just the first option)

    this.selectedOpt = selectedOpt || this.el.querySelector('option'); // create structure

    this._createSelectEl(); // all options


    this.selOpts = [].slice.call(this.selEl.querySelectorAll('li[data-option]')); // total options

    this.selOptsCount = this.selOpts.length; // current index

    this.current = this.selOpts.indexOf(this.selEl.querySelector('li.cs-selected')) || -1; // placeholder elem

    this.selPlaceholder = this.selEl.querySelector('span.cs-placeholder'); // init events

    this._initEvents();
  };
  /**
   * creates the structure for the select element
   */


  SelectFx.prototype._createSelectEl = function () {
    var self = this,
        options = '',
        createOptionHTML = function createOptionHTML(el) {
      var optclass = '',
          classes = '',
          link = '';

      if (el.selectedOpt && !this.foundSelected && !this.hasDefaultPlaceholder) {
        classes += 'cs-selected ';
        this.foundSelected = true;
      } // extra classes


      if (el.getAttribute('data-class')) {
        classes += el.getAttribute('data-class');
      } // link options


      if (el.getAttribute('data-link')) {
        link = 'data-link=' + el.getAttribute('data-link');
      }

      if (classes !== '') {
        optclass = 'class="' + classes + '" ';
      }

      var extraAttributes = '';
      [].forEach.call(el.attributes, function (attr) {
        var name = attr['name'];

        if (name.indexOf('data-') + ['data-option', 'data-value'].indexOf(name) == -1) {
          extraAttributes += name + "='" + attr['value'] + "' ";
        }
      });
      return '<li ' + optclass + link + extraAttributes + ' data-option data-value="' + el.value + '"><span>' + el.textContent + '</span></li>';
    };

    [].slice.call(this.el.children).forEach(function (el) {
      if (el.disabled) {
        return;
      }

      var tag = el.tagName.toLowerCase();

      if (tag === 'option') {
        options += createOptionHTML(el);
      } else if (tag === 'optgroup') {
        options += '<li class="cs-optgroup"><span>' + el.label + '</span><ul>';
        [].slice.call(el.children).forEach(function (opt) {
          options += createOptionHTML(opt);
        });
        options += '</ul></li>';
      }
    });
    var opts_el = '<div class="cs-options"><ul>' + options + '</ul></div>';
    this.selEl = document.createElement('div');
    this.selEl.className = this.el.className;
    this.selEl.tabIndex = this.el.tabIndex;
    this.selEl.innerHTML = '<span class="cs-placeholder">' + this.selectedOpt.textContent + '</span>' + opts_el;
    this.el.parentNode.appendChild(this.selEl);
    this.selEl.appendChild(this.el);
  };
  /**
   * initialize the events
   */


  SelectFx.prototype._initEvents = function () {
    var self = this; // open/close select

    this.selPlaceholder.addEventListener('click', function () {
      self._toggleSelect();
    }); // clicking the options

    this.selOpts.forEach(function (opt, idx) {
      opt.addEventListener('click', function () {
        self.current = idx;

        self._changeOption(); // close select elem


        self._toggleSelect();
      });
    }); // close the select element if the target it´s not the select element or one of its descendants..

    document.addEventListener('click', function (ev) {
      var target = ev.target;

      if (self._isOpen() && target !== self.selEl && !hasParent(target, self.selEl)) {
        self._toggleSelect();
      }
    });
  };
  /**
   * open/close select
   * when opened show the default placeholder if any
   */


  SelectFx.prototype._toggleSelect = function () {
    // remove focus class if any..
    this._removeFocus();

    if (this._isOpen()) {
      if (this.current !== -1) {
        // update placeholder text
        this.selPlaceholder.textContent = this.selOpts[this.current].textContent;
      }

      classie.remove(this.selEl, 'cs-active');
    } else {
      if (this.hasDefaultPlaceholder && this.options.stickyPlaceholder) {
        // everytime we open we wanna see the default placeholder text
        this.selPlaceholder.textContent = this.selectedOpt.textContent;
      }

      classie.add(this.selEl, 'cs-active');
    }
  };
  /**
   * change option - the new value is set
   */


  SelectFx.prototype._changeOption = function () {
    // current option
    var opt = this.selOpts[this.current]; // update current selected value

    this.selPlaceholder.textContent = opt.textContent; // change native select element´s value

    this.el.value = opt.getAttribute('data-value'); // remove class cs-selected from old selected option and add it to current selected option

    var oldOpt = this.selEl.querySelector('li.cs-selected');

    if (oldOpt) {
      classie.remove(oldOpt, 'cs-selected');
    }

    classie.add(opt, 'cs-selected'); // if there´s a link defined

    if (opt.getAttribute('data-link')) {
      // open in new tab?
      if (this.options.newTab) {
        window.open(opt.getAttribute('data-link'), '_blank');
      } else {
        window.location = opt.getAttribute('data-link');
      }
    } // callback


    this.options.onChange(this.el.value);
  };
  /**
   * returns true if select element is opened
   */


  SelectFx.prototype._isOpen = function (opt) {
    return classie.has(this.selEl, 'cs-active');
  };
  /**
   * removes the focus class from the option
   */


  SelectFx.prototype._removeFocus = function (opt) {
    var focusEl = this.selEl.querySelector('li.cs-focus');

    if (focusEl) {
      classie.remove(focusEl, 'cs-focus');
    }
  };
  /**
   * add to global namespace
   */


  window.SelectFx = SelectFx;
})(window);

function PresentationController() {}

PresentationController.prototype = {
  isVisible: false,
  presentedChrome: null,
  presentedContent: null,
  init: function init() {
    var self = this;
    self.presentedChrome.appendTo(document.body);
    var dismissalEvent = this.chromeEventForDismissal();

    if (dismissalEvent) {
      this.presentedChrome.bind(dismissalEvent, function (e) {
        self.dismiss();
      });
    }

    this.dismiss();
  },
  present: function present() {
    this.presentationWillPresent(); // this.presentedContent.css('z-index', 100);

    this.isVisible = true;
  },
  dismiss: function dismiss() {
    this.presentationWillDismiss(); // this.presentedChrome.detach();
    // this.presentedContent.hide();

    this.isVisible = false;
  },
  toggle: function toggle() {
    if (isVisible) {
      this.dismiss();
    } else {
      this.present();
    }
  },
  // @delegation
  delegate: {},
  presentationWillPresent: function presentationWillPresent() {
    if ('presentationWillPresent' in this.delegate) {
      return this.delegate.presentationWillPresent();
    }

    return;
  },
  presentationWillDismiss: function presentationWillDismiss() {
    if ('presentationWillDismiss' in this.delegate) {
      return this.delegate.presentationWillDismiss();
    }

    return;
  },
  chromeEventForDismissal: function chromeEventForDismissal() {
    if ('chromeEventForDismissal' in this.delegate) {
      return this.delegate.chromeEventForDismissal();
    }

    return 'click';
  }
}; // @constructor

function NavigationManager() {}

NavigationManager.prototype = {
  // @properties
  itemSize: 0,
  offset: 0,
  incrementSize: 1,
  stateHistory: [],
  canLoop: false,
  // @methods
  init: function init() {
    this.setOffset(this.offset);
    this.itemSize = this.numberOfItems();
    return;
  },
  advanceOffset: function advanceOffset(delta) {
    delta = delta > -1 ? 1 : -1;
    var newOffset = this.offset + delta * this.incrementSize;
    this.setOffset(newOffset);
  },
  setOffset: function setOffset(newOffset) {
    var maxOffset = this.itemSize - 1;
    var willCycle = false;

    if (this.canLoop) {
      if (newOffset > maxOffset) {
        newOffset = 0;
        willCycle = true;
      } else if (newOffset < 0) {
        newOffset = maxOffset;
        willCycle = true;
      }
    } else {
      newOffset = Math.min(newOffset, maxOffset);
      newOffset = Math.max(newOffset, 0);
    }

    this.offset = newOffset;
    this.pushState();

    if (willCycle) {
      this.willCycleToOffset(this.offset);
    }

    this.transitionToOffset(this.offset);
  },
  pushState: function pushState() {
    var nav = this;
    var newState = {
      itemSize: nav.itemSize,
      offset: nav.offset,
      incrementSize: nav.incrementSize
    };
    nav.stateHistory.push(newState);
    if (nav.stateHistory.length > 30) nav.stateHistory.shift();
    return newState;
  },
  // @delegation
  delegate: null,
  numberOfItems: function numberOfItems() {
    if ('numberOfItems' in this.delegate) {
      return this.delegate.numberOfItems();
    }

    return 0;
  },
  transitionToOffset: function transitionToOffset(offset) {
    if ('transitionToOffset' in this.delegate) {
      return this.delegate.transitionToOffset(offset);
    }

    return;
  },
  willCycleToOffset: function willCycleToOffset(offset) {
    if ('willCycleToOffset' in this.delegate) {
      return this.delegate.willCycleToOffset(offset);
    }

    return;
  }
};

function MegaMenuController() {
  this.menuGroup = $('.megamenu-group');
  this.menus = $('.megamenu');
  this.buttonGroup = $('.site-menu-layout > ._menu');
  this.buttons = $('> a', this.buttonGroup);
  this.logo = $('.site-menu-layout > ._logo, .site-menu-layout > ._accessory');
}

MegaMenuController.prototype = {
  init: function init() {
    // The navigation manager coordinates which megamenu is displayed
    var nm = new NavigationManager();
    this.nm = nm;
    nm.delegate = this;
    nm.init(); // The presentation controller coordinates the display of the megamenu group

    var pc = new PresentationController();
    pc.delegate = this;
    pc.presentedChrome = $('<div class="megamenu-dimming-cell"></div>');
    pc.presentedContent = this.menuGroup;
    pc.init();
    this.buttons.on('mouseover', function (e) {
      var targetOffset = $(this).index(this.buttons);
      nm.setOffset(targetOffset);
    });
    this.buttons.hoverIntent({
      over: function over(e) {
        pc.present();
      },
      out: function out() {},
      sensitivity: 10
    });
    this.logo.on('mouseover', function () {
      pc.dismiss();
    });
    $(document).mouseleave(function () {
      pc.dismiss();
    }); // Mobile menu toggle

    var mobileNavigationList = $('.gm-nav-list');
    $(".gm-icon-toggle").click(function () {
      $(this).toggleClass("open");
      $("body").toggleClass("gm-nav-is-open");
      mobileNavigationList.toggleClass('animated fadeInDown');
    });
  },
  numberOfItems: function numberOfItems() {
    return this.buttons.length;
  },
  transitionToOffset: function transitionToOffset(offset) {
    this.menus.eq(offset).removeClass("previous-item next-item");
    this.menus.eq(offset).prevAll().removeClass('next-item').addClass('previous-item');
    this.menus.eq(offset).nextAll().removeClass('previous-item').addClass('next-item');
    this.buttons.removeClass('open-state');
    this.buttons.eq(offset).addClass('open-state');
  },
  chromeEventForDismissal: function chromeEventForDismissal() {
    return 'mouseover';
  },
  presentationWillPresent: function presentationWillPresent() {
    var itemsToDisplay = this.menuGroup.data("display-all") ? this.nm.numberOfItems() : this.nm.numberOfItems() - 1;

    if (this.nm.offset != itemsToDisplay) {
      $(document.body).addClass('megamenu-open-state');
    }

    if (this.nm.offset == itemsToDisplay) {
      $(document.body).removeClass('megamenu-open-state');
    }
  },
  presentationWillDismiss: function presentationWillDismiss() {
    // console.log('presentation will dismiss');
    this.buttons.removeClass('open-state');
    $(document.body).removeClass('megamenu-open-state').height();
  }
};
$(document).ready(function () {
  var path = "/megamenu";

  if (typeof MegaMenuPath != "undefined") {
    path = MegaMenuPath;
  }

  $.get(path, function (data) {
    $('#megamenu').html(data); // if(typeof angular == 'object') {
    //   // Compile angular tags in the received HTML if Angular is available
    //   var injector = angular.element('#megamenu').injector();
    //   var $compile = injector.get('$compile');
    //   var linkFn = $compile(data);
    //   var $rootScope = injector.get('$rootScope');
    //   var elem = linkFn($rootScope);
    //   $('#megamenu').html(elem);
    // } else {
    //   // Otherwise plop the raw HTML in place
    //   $('#megamenu').html(data);
    // }
    // Initialize the megamenu itself

    var mc = new MegaMenuController();
    mc.init(); // Initialize pagers in the megamenu

    var pageViews = $('#megamenu .page-group');
    pageViews.each(function (i, el) {
      var pc = new PageController();
      pc.initWithContainer(el);
    });
    $('.pagination-group').not('.slick-initialized').slick({
      dots: true
    });
  });
});

function PageController() {// Initialize with initWithContainer
}

PageController.prototype = {
  transitionEnabled: false,
  interval: null,
  initWithContainer: function initWithContainer(container) {
    $(container).addClass('page-controller-initialized');
    this.container = container;
    this.buttons = $('.page-title-menu a', container);
    this.content = $('.page-content', container);
    this.control = $('.page-control', container).eq(0);
    this.traversal = $('.page-traverse a', container);
    this.timer = $('meta[name="page-timer"]', container);
    this.pauseTimer = $('meta[name="page-timer-pause"]', container);
    var pageSelected = false;
    var interval = this.interval = null; // The navigation manager coordinates which contender card is displayed

    var nm = new NavigationManager();
    nm.delegate = this;
    nm.canLoop = true; // Prepare for page transition effect settings

    this.prepareForTransitionEffects();
    $(window).resize({
      'pc': this
    }, function (event) {
      var pc = event.data.pc;
      pc.prepareForTransitionEffects();
    }); // Handle menu button clicks

    this.buttons.on('click', function (e) {
      e.preventDefault();
      var targetOffset = $(this).index();
      setPage(targetOffset);
    }); // Handle traversal button clicks

    this.traversal.on('click', function (e) {
      e.preventDefault();
      var delta = $(this).index() - 1;
      nm.advanceOffset(delta);
      clearInterval(interval);
    }); // Auto setup page controls

    var newButton = $('<a href="#"></a>');
    newButton.on('click', function (e) {
      e.preventDefault();
      var targetOffset = $(this).index();
      setPage(targetOffset);
    });

    if (this.control.length) {
      for (var i = this.numberOfItems() - 1; i >= 0; i--) {
        this.control.append(newButton.clone(true));
      }
    } // Auto setup interval if timer is defined


    function setTimer(time) {
      interval = setInterval(function () {
        nm.advanceOffset(1);
      }, time);
    }

    if (this.timer.length && this.timer.attr('content')) {
      setTimer(this.timer.attr('content'));
    } // Update the navigation manager


    function setPage(targetOffset) {
      if (targetOffset >= 0) {
        nm.setOffset(targetOffset);
        pageSelected = true;
        clearInterval(interval);
      }
    } // Pause timer on hover


    if (this.pauseTimer.length && this.pauseTimer.attr('content')) {
      var timer = this.pauseTimer;
      setTimer(timer.attr('content'));
      $(this.container).mouseover(function () {
        clearInterval(interval);
      });
      $(this.container).mouseout(function () {
        if (!pageSelected) {
          setTimer(timer.attr('content'));
        }
      });
    } // Hide unnecessary controls


    if (this.content.length <= 1) {
      this.control.hide();
      this.traversal.hide();
    } // Init navigation manager


    nm.init();
  },
  numberOfItems: function numberOfItems() {
    return this.content.length;
  },
  willCycleToOffset: function willCycleToOffset(offset) {
    // NOTE: This is technically working, but it's not affecting
    //       the animation properties. The classes change too fast,
    //       in a sense
    var itemAtOffset = this.content.eq(offset);

    if (offset === 0) {
      itemAtOffset.removeClass('previous-item').addClass('next-item');
    } else {
      itemAtOffset.removeClass('next-item').addClass('previous-item');
    }
  },
  transitionToOffset: function transitionToOffset(offset) {
    var activeStateClassName = 'active-state';
    var deactiveStateClassName = 'deactive-state';
    this.buttons.removeClass(activeStateClassName).eq(offset).addClass(activeStateClassName);
    $('a', this.control).removeClass(activeStateClassName).eq(offset).addClass(activeStateClassName); // Holy state management batman!

    var itemAtOffset = this.content.eq(offset); // 1. Deactive any previously active item

    this.content.removeClass(deactiveStateClassName).filter('.' + activeStateClassName).addClass(deactiveStateClassName).removeClass(activeStateClassName); // 2. Activate the item at the current offset

    itemAtOffset.removeClass("previous-item next-item").addClass(activeStateClassName); // 3. Tag items to the left

    itemAtOffset.prevAll().removeClass('next-item').addClass('previous-item'); // 4. Tag items to the right

    itemAtOffset.nextAll().removeClass('previous-item').addClass('next-item');
  },
  prepareForTransitionEffects: function prepareForTransitionEffects() {
    var container = this.container;

    if (container.className.indexOf('page-transition') > -1) {
      this.transitionEnabled = true; // Add class first, which might affect content metrics

      $(container).addClass('page-transition-enabled'); // Set the height of the container to match the tallest content item

      var initialHeight = this.content.eq(0).outerHeight();
      this.content.each(function (i, el) {
        initialHeight = Math.max(initialHeight, $(el).outerHeight());
      });
      $(container).height(initialHeight);
      $(this.content).css('min-height', initialHeight);
    }
  }
};
$(document).ready(function () {
  var pageViews = $('.page-group');
  pageViews.each(function (i, el) {
    var pc = new PageController();
    pc.initWithContainer(el);
  }); //scroll to video

  $('.replay-stream').on('click', function (event) {
    var targetId = $(this).data("target");
    console.log(targetId);
    var target = $("#" + targetId);

    if (target.length) {
      event.preventDefault();
      $('html, body').stop().animate({
        scrollTop: target.offset().top - 100
      }, 500);
    }
  });
});

function createIDs() {
  $('.nav-list-wrapper ul').hide();

  for (var i = 0; i < $('.dropdown-trigger').length; i++) {
    $('.dropdown-trigger')[i].id = "nav-link-" + i;
  }
} // Dynamic Track Logos


var url = document.URL;
var track = "aqueduct";

if (url.indexOf("saratoga") > 0) {
  track = "saratoga";
} else if (url.indexOf("belmont") > 0) {
  track = "belmont";
}

function initLogoMenus() {
  var activeLogo = $("#" + track + "-logo");
  var activeMobileLogo = $("#" + track + "-logo-mobile");
  activeLogo.remove();
  activeMobileLogo.remove();
  $(".main-menu-logo").addClass("inactive-logo");
  $(".inactive-logo").each(function () {
    var newURL = this.href.replace(track, this.id.split("-")[0]);
    $(this).attr("href", newURL);
  });
  activeLogo.addClass("active-logo").insertAfter($("#nyra-logo"));
  $(".active-logo").each(function (index) {
    if (index !== 0) this.remove();
  });
  $(".gm-nav").addClass(track);
  $(".active-logo").click(function (event) {
    if (!this.hasClass('mobile-menu-logo')) {
      $(".logos-container").toggleClass("open");
      $(".logos-container .dropdown-arrow").toggleClass("hide");
    } else {
      switchTrackMobile();
    }

    return false;
  });
  $(".inactive-logo").click(function (event) {
    if (this.hasClass('mobile-menu-logo')) {
      switchTrackMobile();
      return false;
    }
  });
}

function assignMobileTags() {
  if ($(window).width() < 768) $(".main-menu-logo").addClass("mobile-menu-logo").removeClass("main-menu-logo");else $(".mobile-menu-logo").addClass("main-menu-logo").removeClass("mobile-menu-logo");
}

$(".mobile-menu-logo").click(switchTrackMobile);

function switchTrackMobile() {
  var self = event.target.parentNode;
  var currentTrack = $(".gm-nav")[0].className.split(" ")[1];
  var selectedTrack = self.id.split("-")[0];
  $(".gm-nav").removeClass().addClass("gm-nav").addClass(selectedTrack);
  $(".active-logo").removeClass("active-logo");
  $(self).addClass("active-logo");
  switchTrackLinks(currentTrack, selectedTrack);
  return false;
}

function switchTrackLinks(currentTrack, selectedTrack) {
  var links = $('.gm-nav-list li');

  if (selectedTrack === "nyra") {
    var nyraLinks = $(".track-nav .dropdown-container li").clone();
    links.hide();
    nyraLinks.each(function () {
      $(this).addClass("nyra-mobile-link");
      $(".gm-nav.nyra ul").prepend(this);
    });
  } else {
    if (currentTrack === "nyra") {
      $(".nyra-mobile-link").remove();
      currentTrack = $(".gm-nav li a")[0].href.split("-")[1].split(".")[0];
      links.show();
      $('.gm-nav-list li a').each(function () {
        var newURL = this.href.replace(currentTrack, selectedTrack);
        $(this).attr("href", newURL);
      });
    } else {
      $('.gm-nav-list li a').each(function () {
        var newURL = this.href.replace(currentTrack, selectedTrack);
        $(this).attr("href", newURL);
      });
    }
  }
}

$(document).ready(function () {
  createIDs();
  initLogoMenus();
  assignMobileTags();
  $(window).resize(assignMobileTags);
  $('.racing-program .table-row-1').matchHeight();
  $(document).click(function () {
    if ($('.nav-list-wrapper ul').is(':visible')) {
      $('.nav-list-wrapper ul', this).slideUp();
      $('.dropdown-trigger').removeClass('active');
      $('.dropdown-container').removeClass('active');
    }
  }); // Nav Dropdown

  $('.nav-list-wrapper, .nav-list-wrapper .dropdown-icon').click(function (event) {
    var $this = $(this),
        $selector = $this.is('.dropdown-icon') ? $this.parent() : $this;
    event.stopPropagation();
    $selector.toggleClass('active').next().slideToggle(200).toggleClass('active');
  });
});
var hasFlash = false; // Detect if Flash works

try {
  var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');

  if (fo) {
    hasFlash = true;
  }
} catch (e) {
  if (navigator.mimeTypes["application/x-shockwave-flash"] !== undefined) {
    hasFlash = true;
  }
}

var isAndroid = false;

if (navigator.userAgent.match(/Android/i)) {
  isAndroid = true;
} else {
  isAndroid = false;
}
/**
 * selectStream(streamElement, target)
 *
 * Activates the video associated with the submitted HTML element.
 * Uses data-rtmp and data-m3u8 attributes on the element to determine
 * what is going to be played.
 */


function selectStream(selectedStream, target) {
  var $selectedStream = $(selectedStream);
  var rcnFilename = $selectedStream.data('rcn-filename');
  var rtmp = $selectedStream.data('rtmp');
  var m3u8 = $selectedStream.data('m3u8');
  var isVr = $selectedStream.data('vr');
  var isResultsReplay = $selectedStream.data('replay') || false;
  var angle = $selectedStream.data('angle');

  if (typeof angle == undefined) {
    angle = 'pan';
  }

  if (!target) {
    target = $selectedStream.data('target');

    if (!target) {
      target = "PrimaryVideo";
    }
  }

  if (target == "PrimaryVideo") {
    $('._video .stream-option, .replay-stream, #LiveMenu .multi-link').removeClass('active-state');
  }

  if ($('.race-day-live-double-layout').hasClass('multi-camera')) {
    var cameraSelect;
    var id = $selectedStream.data('id');

    if (target == "PrimaryVideo") {
      $('._video').find('[data-id=' + id + ']').addClass('active-state');
      $selectedStream.parents('.multi-link').addClass('active-state');
      cameraSelect = $('._video .multi-channel-dropdown ._select > div');
    } else {
      $('._racing .stream-option').removeClass('active-state');
      $('._racing').find('[data-id=' + id + ']').addClass('active-state');
      cameraSelect = $('._racing .multi-channel-dropdown ._select > div');
    }

    var activeLink = cameraSelect.find('a.active-state').text(); //setActive(activeLink, cameraSelect);
  } else {
    $selectedStream.addClass('active-state');
  }

  playerInstance = jwplayer(target);

  if (rcnFilename.length > 0) {
    // This is an RCN file.  Use getRCNFile() to get an RCN stream URL with the filename
    getRCNFile(rcnFilename, target, isVr, selectedStream, isResultsReplay, true, angle);
    return;
  }

  setJWPLayer(target, rtmp, m3u8, isVr, selectedStream);
  playerInstance.on('play', function () {
    setTimeout(hideVideoAndPromptForLogin, 10000);
  });
  return;
}
/**
 * setJWPLayer(trackId, date, race)
 *
 * Sets up the JWPlayer.
 */


function setJWPLayer(target, source, m3u8, isVr, selectedStream) {
  playerInstance = jwplayer(target);

  if (isVr) {
    // VR videos will not function with flash-enabled players.
    playerInstance.setup({
      playlist: [{
        sources: [{
          file: m3u8
        }],
        stereomode: 'monoscopic'
      }],
      autostart: true,
      hlshtml: 'true',
      width: '100%',
      aspectratio: '16:9',
      skin: 'seven',
      primary: 'html5',
      mute: true
    });
  } else {
    playerInstance.setup({
      playlist: [{
        sources: [{
          file: m3u8,
          type: 'm3u8'
        }, {
          file: source,
          type: 'mp4'
        }]
      }],
      rtmp: {
        subscribe: true,
        bufferlength: '' // Consider using bufferlength: '3' ?

      },
      // advertising: {
      //   client: "vast",
      //   schedule: {
      //     "myAds": {
      //       "offset": "pre",
      //       "tag": "http://52.1.183.40/adserver/www/delivery/fc.php?script=bannerTypeHtml:vastInlineBannerTypeHtml:vastInlineHtml&zones=preroll:0.0-0%3D15&nz=1&source=&r=R0.05822725687175989&block=1&format=vast3&charset=UTF-8"
      //     }
      //   }
      // },
      autostart: true,
      width: '100%',
      aspectratio: '16:9',
      skin: 'seven',
      primary: 'html5',
      fallback: 'flash',
      preload: 'metadata',
      mute: true
    });
  }

  trackStream(selectedStream, target);
  return playerInstance;
}

function selectDefaultStream() {
  if ($('.channel-menu')) {
    $('.channel-menu').find('a').removeClass('active-state');
    $('#LiveMenu a.stream-option').first().addClass('active-state');

    if ($('.channel-menu a.active-state').length > 0) {
      selectStream($('.channel-menu a.active-state').first());
    }
  }
}

function enableMultiCameraCheck() {
  var liveNowLinks = $('#LiveMenu a.stream-option');
  return liveNowLinks.length > 1 ? true : false;
}

function activateMultiCamera() {
  $('[data-video]').removeClass('active');
  $('[data-video="multi"]').addClass('active');
  $('.race-day-live-double-layout').addClass('multi-camera');
  var target = 'SecondaryVideo';
  $('section._racing').prepend('<section class="region-cell multichannel-video-player-secondary-cell"><figure class="multichannel-video-player-secondary"><section class="_player"><div id="' + target + '"></div></section></figure></section>');
  var liveNowLinks = $('#LiveMenu a.stream-option');
  var secondaryVidLink = liveNowLinks.last();
  secondaryVidLink.addClass('secondary-video');
  selectStream(secondaryVidLink, target);
  jwplayer(target).setMute(true);
  var camera1Dropdown = $('._video .multi-channel-dropdown ._select > div');
  var camera2Dropdown = $('._racing .multi-channel-dropdown ._select > div');
  liveNowLinks.each(function () {
    var link = $(this);
    var id = link.text().toLowerCase().split(" ").join("_").replace(/[.,\/#!$%\^&\*;:{}=\-_`~()']/g, "");
    link.attr('data-id', id);
    addToSelect(id, link, camera1Dropdown, 'PrimaryVideo');
    addToSelect(id, link, camera2Dropdown, 'SecondaryVideo');
  });
  camera1Dropdown.parent().prepend('<span class="dropdown-trigger"></span>');
  camera2Dropdown.parent().prepend('<span class="dropdown-trigger"></span>');
  var activeLink1 = camera1Dropdown.find('a.active-state').text();
  camera2Dropdown.find('a.active-state').removeClass('active-state');
  camera2Dropdown.find('.secondary-video').addClass('active-state');
  var activeLink2 = camera2Dropdown.find('a.active-state').text();
  setActive(activeLink1, camera1Dropdown);
  setActive(activeLink2, camera2Dropdown);
}

function setActive(activeLink, dropdown) {
  dropdown.parent().find('span').html('').prepend(activeLink);
}

function addToSelect(id, link, cameraDropdown, target) {
  var dropdownLink = link.clone();
  dropdownLink[0].dataset.target = target;
  dropdownLink.attr('data-id', id);
  cameraDropdown.append(dropdownLink);
}

function destroyMultiCamera() {
  $('.race-day-live-double-layout').removeClass('multi-camera');
  $('[data-video]').removeClass('active');
  $('[data-video="single"]').addClass('active');
  $('section._racing').children().first().remove();
  $('._video .multi-channel-dropdown ._select').html('<div></div>');
  $('._racing .multi-channel-dropdown ._select').html('<div></div>');
}
/**
 * trackStream(streamElement, target)
 *
 * Sends a report to Google Analytics about a stream having been selected.
 */


function trackStream(selectedStream, target) {
  if (typeof ga != "function") {
    // Google Analyitcs isn't present. Let's get out of here.
    return;
  }

  var streamName = $(selectedStream).text();

  if (streamName) {
    ga('send', 'event', 'Stream', 'Start', streamName);
  }
}
/**
 * trackReplay(trackId, date, race)
 *
 * Sends a report to Google Analytics about a replay having been selected.
 */


function trackReplay(trackId, date, race) {
  if (typeof ga != "function") {
    // Google Analyitcs isn't present. Let's get out of here.
    return;
  }

  var trackIdNameMap = {
    '1': 'Aqueduct',
    '2': 'Belmont Park',
    '3': 'Saratoga',
    '415': 'Belmont at the Big A'
  };
  var trackName = trackIdNameMap[trackId];
  var raceNum = race.length < 2 ? '0' + race : race;
  var label = trackName + ' ' + date + '-' + raceNum;
  ga('send', 'event', 'Replay', 'Start', label);
}
/**
 * getRCNReplay(filename, target, m3u8, isVr, selectedStream)
 *
 * Used only for RCN video replays. This function calls the server
 * and generates an RCN video URL for the selected filename. The server
 * needs to be involved to sign the URL for the stream.
 *
 */


function getRCNFile(filename, target, isVr, selectedStream, isReplay, isHD, angle) {
  if (isHD) {
    var url = '/replay/generator.php?filename=' + filename + '&is_replay=' + isReplay + '&is_hd=true&angle=' + angle;
  } else {
    var url = '/replay/generator.php?filename=' + filename + '&is_replay=' + isReplay + '&angle=' + angle;
  }

  $.ajax({
    url: url,
    method: 'GET',
    success: function success(source) {
      var playerInstance = setJWPLayer(target, source, source, isVr, selectedStream);

      if (!isReplay) {
        setTimeout(hideVideoAndPromptForLogin, 10000); // Unbind the error check for SD fallback if it exists

        playerInstance.on('error', function (e) {
          console.error(e);

          if (e.message == "Cannot load M3U8: 404 Not Found" || e.message == "Error loading media: File could not be played") {
            // This stream file is not available/active.  Fallback.
            var message = "<div style='text-align: center;'>";
            message += "<h3 style='margin-bottom:10px;'>This stream is not currently active.</h3>";
            message += "<p>Please check back later.</p>";
            message += "</div>";
            $('#' + target).attr('style', 'width: 100%; height: 100%!important;').html("<div style='display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -moz-box-align: center; -ms-flex-align: center; -webkit-align-items: center; align-items: center; height: 100%;'>" + message + "</div>");
          }
        });
      } else {
        if (isHD) {
          // Error check for SD fallback
          playerInstance.on('error', function (e) {
            console.error(e);

            if (e.message == "Cannot load M3U8: no EXTM3U delimiter" || e.message == "Error loading media: File could not be played") {
              // This is likely a file that doesn't have an HD version. Fetch it again as SD.
              getRCNFile(filename, target, isVr, selectedStream, isReplay, false, angle);
            }
          });
        } else {
          playerInstance.on('error', function (e) {
            console.error(e);
          });
        }
      }
    },
    error: function error(e) {
      console.error(e);
    }
  });
}
/**
 * markSelectedVideoChoice(htmlElement)
 *
 * Triggers CSS classes to indicate that the submitted HTML element
 * is the selected video.
 */


function markSelectedVideoChoice(el) {
  $('.stream-option').removeClass('active-state');
  $('.replay-stream').removeClass('active-state');
  $(el).addClass('active-state');
}

function replayBrowserGetYears() {
  var trackId = $('#TrackSelector').val();
  $.post('/replay-browser/years', {
    trackID: trackId
  }, function (data) {
    $('#YearSelector').html(data);
    replayBrowserGetDates();
  });
}

function replayBrowserGetDates() {
  var trackId = $('#TrackSelector').val();
  var year = $('#YearSelector').val();
  $.post('/replay-browser/dates', {
    trackID: trackId,
    year: year
  }, function (data) {
    $('#DateSelector').html(data);
    replayBrowserSelectDate();
    replayBrowserRender();
  });
}

function replayBrowserSelectDate() {
  $('#ReplayBrowser').submit();
}

function replayBrowserRender() {
  var date = $('#DateSelector').val();
  var trackId = $('#TrackSelector').val();
  var race = $('#RaceSelector').val();
  var m3u8_pan = $('#RaceSelector option:selected').data('hd-replay-pan');
  var m3u8_headon = $('#RaceSelector option:selected').data('hd-replay-headon');
  var targetElementId = 'ReplayBrowserScreen';

  if (m3u8_pan.length == 0) {
    $('#POVSelector option[value="pan"]').attr('selected', false).hide();
    $('#POVSelector option[value="ho"]').attr('selected', 'selected');
  } else {
    $('#POVSelector option[value="pan"]').show();
  }

  if (m3u8_headon.length == 0) {
    $('#POVSelector option[value="ho"]').attr('selected', false).hide();
    $('#POVSelector option[value="pan"]').attr('selected', 'selected');
  } else {
    $('#POVSelector option[value="ho"]').show();
  }

  var pov = $('#POVSelector').val();

  if (pov == "ho") {
    if (m3u8_headon) {
      getRCNFile(m3u8_headon, targetElementId, false, race, true, true, 'ho');
      trackReplay(trackId, date, race);
    }
  } else {
    if (m3u8_pan) {
      getRCNFile(m3u8_pan, targetElementId, false, race, true, true, 'pan');
      trackReplay(trackId, date, race);
    }
  }
}

$(document).ready(function () {
  // Submit Form with Track/Year/Date/Selected Race, triggers first selected race video
  $('#ReplayBrowser').submit(function (event) {
    event.preventDefault();
    var date = $('#DateSelector').val();
    var trackId = $('#TrackSelector').val();
    $.post('/replay-browser/races', {
      trackID: trackId,
      date: date
    }, function (data) {
      $('#RaceSelector').html(data);
      replayBrowserRender();
    });
  });

  if (!enableMultiCameraCheck()) {
    $('.video-camera-select').hide();
  } // VIDEO VISIBILITY TOGGLER
  //


  $('#player-toggle').click(function () {
    if ($('#multi-channel-video-player').hasClass('hidden')) {
      $('#multi-channel-video-player').removeClass('hidden');
      $('#player-toggle').text('hide video');
    } else {
      $('#multi-channel-video-player').addClass('hidden');
      $('#player-toggle').text('show video');
    }
  }); // VIDEO STREAM CLICK HANDLER
  //

  $('body').click(function (e) {
    if ($(e.target).hasClass('stream-option')) {
      setLegacyReplayMode(false);
      selectStream(e.target);
    }
  }); // $('.stream-option').click(function(e){
  //   setLegacyReplayMode(false);
  // });
  // SIDE-BY-SIDE CLICK HANDLER

  $('.camera-select').click(function (e) {
    var type = $(this).data('video');
    var isActive = $(this).hasClass('active');

    if (!isActive) {
      type == 'single' ? destroyMultiCamera() : activateMultiCamera();
    }
  }); // Set Custom Camera Dropdown Labels  

  $('.multi-channel-dropdown').on('click', 'a.stream-option', function () {
    var buttonText = $(this).text();
    $(this).parent().siblings(".dropdown-trigger").text(buttonText);
  });
  var vidSelect = $('.multi-channel-dropdown ._select');
  vidSelect.click(function (e) {
    var el = $(this).closest('._select');
    el.addClass('active');
  });
  $('body').click(function (e) {
    var target = e.target;

    if (!$(target).is('._select') && !$(target).parents().is('._select') || $(target).is('.stream-option')) {
      vidSelect.removeClass('active');
    }
  }); // REPLAY CLICK HANDLER

  $(document).on('click', 'a.replay-stream', function (e) {
    markSelectedVideoChoice(this);
    var target = $(this).data('target'); // HD Video mode

    var hdFilename = $(this).data('m3u8');
    var rcnFilename = $(this).data('rcn-filename');

    if (hdFilename && hdFilename.length > 0 || rcnFilename && rcnFilename.length > 0) {
      selectStream(this, target);
      return;
    } // Fallthrough: Legacy (iframe) RCN Video mode


    var filename = $(this).data('replay-filename');
    selectLegacyReplay(filename, target);
  }); // REPLAY BROWSER ACTIVATION

  if ($('#ReplayBrowser').length > 0) {
    replayBrowserRender();
  } // DEFAULT STREAM SELECTOR


  selectDefaultStream();
});
/**
 * getLegacyReplay(filename, width, height, mode, target)
 *
 * Used only for Legacy RCN video replays (via iframe). This function calls the server
 * and generates an RCN video URL for the selected filename. The server
 * needs to be involved to sign the URL for the stream.
 *
 */

function getLegacyReplay(filename, width, height, mode, target) {
  var url = '/replay/legacy_generator.php?filename=' + filename + '&width=' + width + '&height=' + height + '&mode=' + mode + '&angle=pan';
  $.ajax({
    url: url,
    method: 'GET',
    success: function success(data) {
      showLegacyReplay(data, width, height, target);
    },
    error: function error(e) {
      setLegacyReplayMode(false, target);
    }
  });
}
/**
 * showLegacyReplay(url, width, height)
 *
 * Used for RCN legacy video replays. Creates an iframe for the video
 * and call setLegacyReplayVideo(true), which hides the JWPlayer
 */


function showLegacyReplay(generatedUrl, width, height, target) {
  if (isAndroid) {
    document.location.href = generatedUrl;
    return;
  }

  if (!target) {
    target = 'PrimaryVideo';
  }

  if (typeof jwplayer(target).remove == 'function') jwplayer(target).remove();
  $('iframe', $('#' + target)).remove(); // Remove existing replay video iframes before we build a new one

  var iframe = document.createElement('iframe');
  iframe.setAttribute('src', generatedUrl);
  iframe.setAttribute('style', 'width: ' + width + 'px; height: ' + height + 'px; display: block;margin: auto;');
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('seamless', 'seamless');
  iframe.setAttribute('scrolling', 'no');
  $('#' + target).append(iframe);
  setLegacyReplayMode(true, target);
}
/**
 * setLegacyReplayMode(bool)
 *
 * Toggle function to enable or disable the JWPlayer display area.
 */


function setLegacyReplayMode(isLegacy, target) {
  if (!target) {
    target = 'PrimaryVideo';
  }

  if (isLegacy) {
    if (typeof jwplayer(target).remove == 'function') jwplayer(target).remove();
  } else {
    $('iframe', $('#' + target)).remove();
  }
}
/**
 * selectLegacyReplay(filename)
 *
 * Handles RCN video replays. Only triggered for older replays
 * that pre-date the HD video system setup.
 *
 */


function selectLegacyReplay(filename, target) {
  if (!target) {
    target = 'PrimaryVideo';
  }

  var width = 400;
  var height = 300;
  var origWidth = $('#' + target).width();
  var origHeight = $('#' + target).height();

  if (origHeight > 0) {
    height = origHeight;
    width = Math.round(height * (16 / 10));
  } else if (origWidth > 0) {
    width = origWidth;
    height = Math.round(width * (10 / 16));
  }

  var mode = hasFlash ? 'flash' : 'mobile'; // filename = hasFlash ? filenameFlash : filenameStd;

  getLegacyReplay(filename, width, height, mode, target);
}

$(document).ready(function () {
  var activeRace;
  var displayMode;

  if (window.location.hash) {
    var hash = window.location.hash;
    var matches = hash.match(/^#race(\d+)-(entries|results)/);

    if (matches && matches.length > 1) {
      activeRace = matches[1];
      displayMode = matches[2];
    }

    matches = hash.match(/^#(entries|results)/);

    if (matches && matches.length) {
      displayMode = matches[1];
    }
  }

  if (!activeRace && !displayMode) {
    activeRace = window.initialActiveRace;
    displayMode = window.initialDisplayMode;
  } else if (!activeRace && displayMode) {
    activeRace = displayMode == 'entries' ? 1 : 1;
  }

  setRaceView(activeRace, displayMode);
});
var displayModeToggle = {
  'entries': 'results',
  'results': 'entries'
};

function setRaceView(activeRace, displayMode) {
  $('.race-detail-layout').hide();
  $('[data-race-number=' + activeRace + ']').show();

  if ($('#' + displayMode + '-' + activeRace)) {
    $('#' + displayMode + '-' + activeRace).show();
    $('#' + displayModeToggle[displayMode] + '-' + activeRace).hide();
    $('[data-view-link=' + activeRace + '-' + displayMode + ']').addClass('active-state');
    $('[data-view-link=' + activeRace + '-' + displayModeToggle[displayMode] + ']').removeClass('active-state');
  } else {
    $('#' + displayModeToggle[displayMode] + '-' + activeRace).show();
  }

  $('.race-day-race-menu a').removeClass('active-state');
  $('.race-' + activeRace + '-menu-item').addClass('active-state');
}

$(document).ready(function () {
  $(".search-icon, body").click(function () {
    if (event.target.className === "fa fa-search search-icon" || event.target.id === "search-box") {
      if ($(".search-box").hasClass("open")) {
        $(".search-box").removeClass("open");
        $(".search-box").val("");
      } else {
        $(".search-box").addClass("open");
      }
    } else {
      $(".search-box").removeClass("open");
      $(".search-box").val("");
    }
  });
  $("#sticky-expander").click(function () {
    $(".sticky-footer").toggleClass("open");
    $("body").toggleClass("sticky-footer-open");
    this.className.includes('up') ? $(this).removeClass().addClass("fa fa-chevron-circle-down") : $(this).removeClass().addClass("fa fa-chevron-circle-up");
  });
  checkFooterHeight();
  $(window).resize(checkFooterHeight);
});

function checkFooterHeight() {
  var windowHeight = $(window).height();
  var contentHeight = $("body").outerHeight();

  if (windowHeight > contentHeight + 100) {
    $(".sticky-footer").css({
      "bottom": windowHeight - (contentHeight + 100)
    });
  } else {
    $(".sticky-footer").css({
      "bottom": "0"
    });
  }
}

function isNyraBetsUser(punterClassification) {
  return "Basic" === punterClassification;
}

function splitName(fullNameStringFromAPI) {
  var nameArray = [];

  if (fullNameStringFromAPI == null) {
    nameArray[0] = "";
    nameArray[1] = "";
  } else {
    var apiNameArray = fullNameStringFromAPI.split("\n");

    if (apiNameArray.length == 3) {
      nameArray[0] = apiNameArray[0]; // firstname

      nameArray[1] = apiNameArray[2]; // lastname
    } else {
      nameArray[0] = "";
      nameArray[1] = "";
    }
  }

  return nameArray;
}

function splitAddress(addressStringFromAPI) {
  var addressArray = [];

  if (addressStringFromAPI == null) {
    addressArray[0] = "";
    addressArray[1] = "";
    addressArray[2] = "";
    addressArray[3] = "";
  } else {
    var apiAddressArray = addressStringFromAPI.split("\n");

    if (apiAddressArray.length == 5) {
      addressArray[0] = apiAddressArray[0] + ' ' + apiAddressArray[1]; // address 1 and 2

      addressArray[0] = addressArray[0].trim();
      addressArray[1] = apiAddressArray[2]; // city

      addressArray[2] = apiAddressArray[3]; // state

      addressArray[3] = apiAddressArray[4]; // zip
    } else {
      addressArray[0] = "";
      addressArray[1] = "";
      addressArray[2] = "";
      addressArray[3] = "";
    }
  }

  return addressArray;
}

function getHandicappingContestInfoFromIAPIResponse(responseObject) {
  var KJUR = require('jsrsasign');
  /*
  First Name
    Last Name
    Street Address
    City
    State
    Zip Code
    Phone Number
    Email Address
    Date of Birth
    NYRA Bets Member Number (Optional)
    */


  var object = {};

  if (responseObject != null) {
    if (responseObject.name) {
      var nameArray = splitName(responseObject.name);
      object.firstName = nameArray[0];
      object.lastName = nameArray[1];
    }

    if (responseObject.address) {
      var addressArray = splitAddress(responseObject.address);
      object.streetAddress = addressArray[0];
      object.city = addressArray[1];
      object.state = addressArray[2];
      object.zipCode = addressArray[3];
    }

    if (responseObject.phone) {
      object.phone = responseObject.phone;
    }

    if (responseObject.email) {
      object.email = responseObject.email;
    }

    if (responseObject.birthDate) {
      object.birthDate = responseObject.birthDate;
    }
  }

  var privateKeyForSignatureOfJWT = ""; // Seee https://kjur.github.io/jsrsasign/api/symbols/KJUR.jws.JWS.html for more

  sJWS = KJUR.jws.JWS.sign(null, {
    alg: "HS256",
    cty: "JWT"
  }, object, privateKeyForSignatureOfJWT);
  return sJWS;
} // editor provides a list of full state names in a template variable, but the API
// provides only the state abbreviation. This code maps the two.


function isUserEligibleForOnlineContest(handicappingContestInfoJWT) {
  var KJUR = require('jsrsasign');

  var state = KJUR.jws.JWS.parse(handicappingContestInfoJWT).payloadObj.state;
  return isUserEligibleForOnlineContestBasedOnState(state, listOfHandicappingOperationalStates());
}

function isUserEligibleForOnlineContestBasedOnState(state, listOfHandicappingOperationalStates) {
  return listOfHandicappingOperationalStates.indexOf(state) > -1;
}

function listOfHandicappingOperationalStates() {
  return listOfAbbreviatedStates(handicappingOperationalStates());
}

function listOfAbbreviatedStates(listOfStateNamesFromTemplateVariable) {
  var abbreviatedStateList = [];

  if (listOfStateNamesFromTemplateVariable == "" || listOfStateNamesFromTemplateVariable == null) {
    return abbreviatedStateList;
  }

  var fullStateNamesArray = listOfStateNamesFromTemplateVariable.split(",");

  for (var i = 0; i <= fullStateNamesArray.length; i++) {
    var stateName = "";

    if (fullStateNamesArray[i] != null) {
      stateName = fullStateNamesArray[i].trim();
    }

    var stateAbbrev = lookupStateAbbreviationByName(stateName);

    if (stateAbbrev != "") {
      abbreviatedStateList.push(stateAbbrev);
    }
  }

  return abbreviatedStateList;
}

function buildPrepopulateQueryString(payload, memberNumber) {
  if (payload === "") {
    return "";
  }

  var formPositionToPayloadKey = {}; // these numbers are form positions that we have been promised will "never change"

  formPositionToPayloadKey[2] = "firstName";
  formPositionToPayloadKey[3] = "lastName";
  formPositionToPayloadKey[4] = "streetAddress";
  formPositionToPayloadKey[5] = "city";
  formPositionToPayloadKey[6] = "state";
  formPositionToPayloadKey[7] = "zipCode";
  formPositionToPayloadKey[9] = "phone";
  formPositionToPayloadKey[10] = "email";
  formPositionToPayloadKey[11] = "birthDate";
  formPositionToPayloadKey[13] = "memberNumber";
  var queryParamString = "";

  for (var key in formPositionToPayloadKey) {
    queryParamString += key;
    queryParamString += "=";
    payloadValue = payload[formPositionToPayloadKey[key]];

    if (payloadValue === undefined) {
      payloadValue = "";
    }

    var turnFromZeroOffsetToOneOffset = 1;

    if (formPositionToPayloadKey[key] === "state") {
      var payloadValueForState = "";
      Object.keys(ABBREVIATION_TO_STATE_NAME).forEach(function (key, index) {
        if (payloadValue && payloadValue.toUpperCase() == key.toUpperCase()) {
          payloadValueForState = index + turnFromZeroOffsetToOneOffset;
        }
      });
      payloadValue = payloadValueForState;
    } else if (formPositionToPayloadKey[key] === "birthDate") {
      var payloadValueForBirthDate = "";
      var date = new Date(payloadValue);

      if (isNaN(date) == false) {
        payloadValueForBirthDate = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
      }

      payloadValue = payloadValueForBirthDate;
    }

    queryParamString += encodeURIComponent(payloadValue);
    queryParamString += "&";
  }

  return queryParamString.slice(0, -1);
}

function lookupStateAbbreviationByName(fullStateName) {
  if (fullStateName == "" || fullStateName == null) {
    return "";
  } // from https://stackoverflow.com/questions/9907419/how-to-get-a-key-in-a-javascript-object-by-its-value


  var stateAbbrev = Object.keys(ABBREVIATION_TO_STATE_NAME).filter(function (abbreviation) {
    return ABBREVIATION_TO_STATE_NAME[abbreviation].toLowerCase() === fullStateName.toLowerCase();
  })[0];

  if (stateAbbrev) {
    return stateAbbrev;
  } else {
    return "";
  }
}

var ABBREVIATION_TO_STATE_NAME = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};
/**
 * Formats an expire string for a cookie.
 *
 * @param {Date} date
 * @return {String}
 */

function formatCookieExpiresString(date) {
  if (!date) {
    date = new Date(); // Expire cookie in 14 days

    date.setTime(date.getTime() + 14 * 24 * 60 * 60 * 1000);
  }

  return date.toUTCString();
}
/**
 * Get the root domain of the current location.
 *
 * NOTE: This will only succeed on single-suffix domains, like example.com.
 * It will fail spectacularly on domains like example.co.uk.
 *
 * @param {String} domain
 * @return {String}
 */


function getRootDomain(domain) {
  if (!domain) {
    domain = location.hostname;
  }

  return domain.split('.').reverse().splice(0, 2).reverse().join('.');
}
/**
 * Set a cookie.
 *
 * @param {String} key
 * @param {String} value
 * @param {Boolean} expire Expire the cookie immediately.
 */


function setCookie(key, value, expire) {
  var expires;

  if (expire === true) {
    expires = formatCookieExpiresString(new Date('Thu, 01 Jan 1970 00:00:01 GMT'));
  } else {
    expires = formatCookieExpiresString();
  }

  document.cookie = key + '=' + value + ';expires=' + expires + '; path=/; domain=.' + getRootDomain();
}
/**
 * Get a cookie value.
 *
 * @param {String} key
 * @return {String|null}
 */


function getCookie(key) {
  var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  return keyValue ? keyValue[2] : null;
}
/**
 * Prompts user to login to NYRA for handicapping challenge links
 */


$('.handicapping-challenge-modal .close-modal').click(function (event) {
  event.preventDefault();
  $.modal.close();
});
/**
 * This global (I know!) variable is what the login callback users to determine if we click an onsite game or an online game.
 * the online game has more strict requirements.
 */

var handicappingClickType = null;
/**
 * This global (I know!) variable is what the login callback users to determine the url for the game.
 */

var handicappingOnlineGameURLBase = null;
/**
 * jquery element setup for handicapping challenge.
 */

function setupRequireUserToLoginToForHandicappingChallenge() {
  $.modal.close();
  $("[data-contest-type='online']").on('click', function (e) {
    var contestType = "online";
    handicappingClickType = contestType;
    handicappingOnlineGameURLBase = $(e.currentTarget).data("href");
    requireUserToLoginToForHandicappingChallenge(e, contestType);
    return false;
  });
}
/**
 * If user is a full nyrabets user and from an eligible state, we allow them to go to the game site.
 */


function isEligibleToPlayOnlineGameOrShowModals() {
  var fullNyraUser = isThisUserAFullNyraBetsUser(); // not a nyra bets full user, need to get more info

  if (!fullNyraUser) {
    $.modal.close();

    if (isUserLoggedIn()) {
      $('#handicapping-not-nyrabets-user').modal({
        showClose: false,
        fadeDuration: 250,
        fadeDelay: 0.80
      });
    }

    return false;
  } // a full nyra user, now check state info


  var handicappingContestInfoJWT = getHandicappingContestInfoFromLoginCookie();
  var userEligibleForContest = fullNyraUser && handicappingContestInfoJWT != null && isUserEligibleForOnlineContest(handicappingContestInfoJWT);

  if (!userEligibleForContest) {
    $.modal.close();
    $('#handicapping-no-contest-available').modal({
      showClose: false,
      fadeDuration: 250,
      fadeDelay: 0.80
    });
    return false;
  }

  return true;
}
/**
 * logic around handicapping page for login.
 */


function requireUserToLoginToForHandicappingChallenge(event, contestType) {
  // https://github.com/kylefox/jquery-modal#events
  var postLoginCallback = function postLoginCallback(modalEvent, modal) {
    // they have met all the requirements, send them on their way
    if (isEligibleToPlayOnlineGameOrShowModals()) {
      var link = getHandicappingForwardURL($(event.currentTarget).data("href"));
      window.location = link;
    }
  }; // If user didn't need to login, then send them on their way.
  // This function confusingly returns the user's _prior_ login status.


  if (requireUserToLogin(event, postLoginCallback)) {
    postLoginCallback();
  }
}
/**
 * Get handicapping info from login cookie if available for contests
 *
 * return false unless this user is a full fledged nyra bets user
 */


function isThisUserAFullNyraBetsUser() {
  var cookie = getCookie('punter');

  if (!cookie) {
    return false;
  }

  try {
    var val = JSON.parse(cookie);

    if (val && val.isNyraBetsUser) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }

  return false;
}
/**
 * Get handicapping info from login cookie if available for contests
 *
 * return null if not present
 */


function getHandicappingContestInfoFromLoginCookie() {
  var cookie = getCookie('punter');

  if (!cookie) {
    return null;
  }

  try {
    var val = JSON.parse(cookie);

    if (val.handicappingContestInfoJWT) {
      return val.handicappingContestInfoJWT;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
/**
 * Get handicapping info from login cookie if available for contests
 *
 * return null if not present
 */


function getNyraAccountNumberFromLoginCookie() {
  var cookie = getCookie('punter');

  if (!cookie) {
    return null;
  }

  try {
    var val = JSON.parse(cookie);

    if (val.nyraAccountNumber) {
      return val.nyraAccountNumber;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
/**
 * Appends the formsite form field query string which prepopulates the form
 *
 * return url to forward to
 */


function getHandicappingForwardURL(url) {
  var KJUR = require('jsrsasign');

  var handicappingContestInfo = getHandicappingContestInfoFromLoginCookie();

  if (handicappingContestInfo) {
    var payload = KJUR.jws.JWS.parse(handicappingContestInfo).payloadObj; // special handling of memberNumber because we get it from a different call

    payload["memberNumber"] = getNyraAccountNumberFromLoginCookie();
    var qs = buildPrepopulateQueryString(payload);
    url += "?" + qs;
  }

  return url;
}
/**
 * Hide video from logged out users and prompt for login.
 */


function hideVideoAndPromptForLogin() {
  if (isThisUserAFullNyraBetsUser()) {
    $('.stream-select').removeAttr('disabled');
    return;
  } else {
    if (typeof jwplayer !== 'undefined') {
      if (typeof jwplayer().pause === 'function') {
        jwplayer().pause();
      }
    }

    $('.stream-select, .camera-select').attr('disabled', 'disabled');
    $('.multichannel-video-player, .multi-channel-dropdown, .video-camera-select, .multichannel-video-player-secondary').hide();

    if (isUserLoggedIn()) {
      $('#nyrabets-upgrade-splash').show();
    } else {
      $('#nyrabets-login-splash').show();
    }
  }
}
/**
 * Check if a user is logged in.
 *
 * @return {Boolean}
 */


function isUserLoggedIn() {
  return getCookie('punter') ? true : false;
}
/**
 * Set the logged in cookie.
 *
 * @param {Object} data
 */


function setLoginCookie(data) {
  setCookie('punter', JSON.stringify(data));
}
/**
 * Get the logged in user data.
 *
 * @return {Object}
 */


function getLoginCookieData() {
  var data = getCookie('punter');

  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }

  return null;
}
/**
 * Log a user out.
 */


function logout() {
  // Expire cookies.
  setCookie('jwt', 'logout', true);
  setCookie('punter', 'logout', true); // clear any login forms that are hidden

  $("[data-action='login'] input").val("");
  toggleLoggedInViewState();
}
/**
 * Toggle various view elements when a user logs in or out.
 */


function toggleLoggedInViewState() {
  toggleLoadingState(false);

  if (isUserLoggedIn()) {
    var punterData = getLoginCookieData();

    if (punterData) {
      $('.loggedin .name').text(punterData.name);
    }

    $('.loggedin').removeClass('hidden');
    $('.loggedout').addClass('hidden');
  } else {
    $('.loggedout').removeClass('hidden');
    $('.loggedin').addClass('hidden');
    $('.loggedin .name').empty();
  }
}
/**
 * jquery element check to see if we are going to force a user to login on a page.
 */


function setupRequireUserToLogin() {
  if ($("[data-checklogin='true']").length && $("#login-splash").length) {
    $.modal.close();
    $("[data-checklogin='true']").on('click', function (e) {
      requireUserToLogin();
    });
  }
}
/**
 * we force the user to login if they are not currently logged in.
 * return falce if user is not logged in.
 */


function requireUserToLogin(event, postLoginCallback) {
  var userLoggedIn = isUserLoggedIn(); // if not logged in, force them to login

  if (!userLoggedIn) {
    // Allow a callback to be fired before the modal is closed.
    $('#login-splash').on($.modal.BEFORE_CLOSE, postLoginCallback || $.noop); // Setup the modal for logging a user in.

    $('#login-splash').modal({
      showClose: false,
      fadeDuration: 250,
      fadeDelay: 0.80
    });
  } else {
    $.modal.close();
  }

  return userLoggedIn;
}
/**
 * Authorize a user with the API.
 *
 * @param  {String} username
 * @param  {String} password
 * @param  {Function} callback
 */


function authorizeUser(username, password, recaptchaToken, callback) {
  /**
   * @example
      request = {
        "header": {
          "version": 2,
          "fragmentLanguage": "Javascript",
          "fragmentVersion": "",
          "clientIdentifier": "nyra.com"
        },
        "partnerUsername": "__ENCODED_USERNAME__",
        "cleartextPassword": "__ENCODED_PASSWORD__",
        "currency": "USD",
        "integrationPartnerId": 7,
        "language": "en",
        "clientIdentifier": "nyra.com",
        "granularChannelType": 5,
        "channelInformation": "NYRA Rewards website - NYRAPLUS",
        "initialLanguage": "en",
        "numberLogonAttemptsToReturn": 0,
        "sessionCategory": 1
      }
  */
  data_to_send = {
    "header": {
      "version": 2,
      "fragmentLanguage": "Javascript",
      "fragmentVersion": "",
      "clientIdentifier": "nyra.com"
    },
    "partnerUsername": username,
    "cleartextPassword": password,
    "currency": "USD",
    "integrationPartnerId": 7,
    "language": "en",
    "clientIdentifier": "nyra.com",
    "granularChannelType": 5,
    "channelInformation": "NYRA Rewards website - NYRAPLUS",
    "initialLanguage": "en",
    "numberLogonAttemptsToReturn": 0,
    "sessionCategory": 1
  };

  if (recaptchaToken) {
    data_to_send.reCAPTCHAToken = recaptchaToken;
  }

  encoded_data_to_send = 'request=' + encodeURIComponent(JSON.stringify(data_to_send));
  $.ajax({
    url: 'https://brk0201-iapi-webservice.nyrabets.com/EstablishSession.aspx',
    method: 'POST',
    data: encoded_data_to_send,
    contentType: 'application/x-www-form-urlencoded',
    success: function success(data) {
      if (data && data.returnCode == 0) {
        // Successful authentication.
        callback(null, data);
      } else if (data && data.returnCode == 958) {
        callback({
          type: 'captcha-required'
        });
        return;
      } else if (data && data.returnCode == 848) {
        // Unverified email.
        setCookie('unverified_username', username);
        callback({
          type: 'unverified'
        });
        return;
      } else {
        callback({
          type: 'inline',
          message: 'You have entered invalid credentials. Please try again.'
        });
      }
    },
    error: function error() {
      callback({
        type: 'inline',
        message: 'Error when attempting to sign in. Please try again.'
      });
    }
  });
}
/**
 * Get a punter's account number from the API.
 *
 * @param {String} sessionToken
 * @return {jQuery.Deferred}
 */


function getNyraAccountNumber(sessionToken) {
  var request = {
    "header": {
      "version": 2,
      "fragmentLanguage": "Javascript",
      "fragmentVersion": "",
      "clientIdentifier": "nyra.com"
    },
    "name": "NYRA.AccountNumber",
    "sessionToken": "XXXSESSIONTOKENXXX"
  };
  return $.ajax({
    url: 'https://brk0201-iapi-webservice.nyrabets.com/GetPunterTaggedValue.aspx',
    method: 'POST',
    data: addProperlyEncodedSessionTokenToRequestString(request, sessionToken, "XXXSESSIONTOKENXXX"),
    contentType: 'application/x-www-form-urlencoded'
  });
}
/**
 * Get a punter's details from the API.
 *
 * @param {String} sessionToken
 * @return {jQuery.Deferred}
 */


function getPunterDetails(sessionToken) {
  var request = {
    "header": {
      "version": 2,
      "fragmentLanguage": "Javascript",
      "fragmentVersion": "",
      "clientIdentifier": "nyra.com"
    },
    "sessionToken": "XXXSESSIONTOKENXXX"
  };
  return $.ajax({
    url: 'https://brk0201-iapi-webservice.nyrabets.com/GetPunterDetails.aspx',
    method: 'POST',
    data: addProperlyEncodedSessionTokenToRequestString(request, sessionToken, "XXXSESSIONTOKENXXX"),
    contentType: 'application/x-www-form-urlencoded'
  });
}
/**
 * Get a JSON Web Token for a user.
 *
 * @param {String} sessionToken
 * @return {jQuery.Deferred}
 */


function getJsonWebToken(sessionToken) {
  /**
   * @example
      request = {
        "header": {
          "version": 2,
          "fragmentLanguage": "Javascript",
          "fragmentVersion": "",
          "clientIdentifier": "nyra.com"
        },
        "sessionToken": "__ENCODED_SESSION_TOKEN__"
      }
  */
  return $.ajax({
    url: 'https://brk0201-iapi-webservice.nyrabets.com/GetJSONWebToken.aspx',
    method: 'POST',
    data: 'request=%7B%22header%22%3A%7B%22version%22%3A2%2C%22fragmentLanguage%22%3A%22Javascript%22%2C%22fragmentVersion%22%3A%22%22%2C%22clientIdentifier%22%3A%22nyra.com%22%7D%2C%22sessionToken%22%3A%22' + encodeURIComponent(sessionToken) + '%22%7D',
    contentType: 'application/x-www-form-urlencoded'
  });
}

function getSavedJsonWebToken() {
  return getCookie('jwt');
}

function addJsonWebTokenToUrls() {
  // Attach the handler to the document, so dynamically added links get handled.
  $('body').on('click', 'a[href*="nyrabets.com"]', function (event) {
    var jwt = getSavedJsonWebToken();

    if (jwt) {
      var originalHref = this.href,
          url = 'https://www.nyrabets.com';

      if (!shouldAddSSOJWT(this.href)) {
        return;
      } // Append the hash so the client app takes the user to the correct page.
      // TODO: This breaks login. They need to fix this on their end somehow.
      // if (this.hash) {
      //   url += this.hash;
      // }
      // Append the token.
      // TODO: This logic might need changed when we support race params.


      url += '#token=' + jwt; // We rewrite the href on click instead of once-and-forget
      // so we don't have deal with rewriting on login/logout, etc.

      this.href = url; // Reset the href after five seconds, so it honors logouts.

      setTimeout(function () {
        this.href = originalHref;
      }, 5000);
    }
  });
}
/**
 * Re-send a verification email to an unverified user.
 *
 */


function resendVerificationEmail() {
  var errMessage = '<div>Sorry, something went wrong. Please contact NYRA Bets help <a href="https://www.nyrabets.com/#contact-help" target="_blank">here.</a></div>';
  var request = {
    header: {
      version: 2,
      fragmentLanguage: "Javascript",
      fragmentVersion: "",
      clientIdentifier: "nyra.com"
    },
    customServiceName: "NYRA.ResendEmail",
    nameValuePairs: [{
      name: "username",
      value: getCookie('unverified_username')
    }, {
      name: "integrationPartnerId",
      value: 7
    }]
  };
  $.ajax({
    url: 'https://brk0201-iapi-webservice.nyrabets.com/PerformCustomService.aspx',
    method: 'POST',
    data: encodeRequestData(request),
    success: function success(data) {
      if (data && data.returnCode == 0) {
        activateHelpTarget('general', 'Activation email has been sent to you! Follow the instructions in the email to activate your NYRA account. Once activated, you will be able to upgrade to a NYRA Bets account.', {
          name: 'Close',
          trigger: 'close'
        });
      } else {
        showError(errMessage);
      }
    },
    error: function error(e) {
      showError(errMessage);
    }
  });
}
/**
 * Transform a javascript object into a url encoded
 * string for use as data object in an IAPI POST request.
 *
 * @param {Object} data
 * @return {String}
 */


function encodeRequestData(data) {
  return 'request=' + encodeURIComponent(JSON.stringify(data));
}
/**
 * Delete old cookies. This forces a new login.
 */


function deleteOldCookies() {
  // These don't use setCookie() since the hostname is different.
  document.cookie = 'auth=deleted; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.' + location.hostname;
  document.cookie = 'punterName=deleted; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.' + location.hostname;
}
/**
 * Perform type-specific actions on an error.
 *
 * @param {String} type
 * @param {String} message
 */


function handleErrorType(type, message) {
  switch (type) {
    case 'unverified':
      activateHelpTarget('general', 'You currently do not have an active NYRA account, but you do have an inactive NYRA account. To activate your account, click the button below to have the activation link sent to the email address you provided previously.', {
        name: 'Resend Email Link',
        trigger: 'resend-email'
      });
      break;

    default:
      showError(message || 'Error when attempting to sign in. Please try again.');
  }
}
/**
 * Handle an error returned from another function.
 *
 * Renders a generic message if the error is not formed properly,
 * otherwise handles that error type.
 *
 * @param {String} type
 * @param {String} message
 */


function handleReturnedError(opts) {
  if (!opts || !opts.type) {
    showError('Error when attempting to sign in. Please try again.');
  } else {
    handleErrorType(opts.type, opts.message);
  }
}
/**
 * Show an error message.
 *
 * @param {String} message
 */


function showError(message) {
  activateHelpTarget('general', '<div class="error-message">' + message + '</div>');
}

function showInlineError(elementId, context, errorMessage) {
  var $error = $('.error-message[for="' + elementId + '"]', context);

  if (errorMessage) {
    $error.text(errorMessage);
  }

  $error.show();
  $('input[name="' + elementId + '"]', context).addClass('error-input');
}

function removeInlineError(elementId, context) {
  $('.error-message[for="' + elementId + '"]', context).hide();
  $('input[name="' + elementId + '"]', context).removeClass('error-input');
}
/**
 * Activate or inactivate the loading state of the form wrapper.
 * Loading state ("-loading" class) shows a spinner, while
 * an "unloaded" state ("-loading" class absent) shows the form.
 *
 * @param {Boolean} state
 */


function toggleLoadingState(state) {
  state ? $(".loggedout").addClass("-loading") : $(".loggedout").removeClass("-loading");
}
/**
 * Sets Capctcha required in a hidden field in the form.
 * Shows the Google Captcha
 *
 *
 */


function showCaptcha() {
  $("#recaptchaDiv").show();
}
/**
 * Activates the dialog showing login help.
 * This currently includes help for "Forgot Username" and
 * "Forgot Password", as well as suggestions when a login
 * attempt returns an error.
 *
 * Target can be one of "username", "password", or "general"
 *
 * @param {String} target
 * @param {String} content
 * @param {{name: string, trigger: string}} actionButton
 */


function activateHelpTarget(target, content, actionButton) {
  var helpWrapper = $('[data-help="' + target + '"]');

  if (content) {
    helpWrapper.find('[data-help-content]').html(content);
  }

  if (actionButton && actionButton.name && actionButton.trigger) {
    var actionButtonEl = helpWrapper.find('[data-help-action-button]');
    actionButtonEl.data('help-action-button', actionButton.trigger);
    actionButtonEl.text(actionButton.name);
    actionButtonEl.show();
  } // Move the element to the top of the body to avoid situations where a
  // parent with "position: relative" distorts the size/positioning.
  // We need it to fill the entire viewport.


  if (helpWrapper.length) {
    $(document.body).prepend(helpWrapper); // Trigger a document reflow to allow the css transitions to occur properly

    setTimeout(function () {
      helpWrapper.addClass('active');
    }, 1);
  }
}
/**
 * Clears currently active help target dialog.
 *
 * @param {HTMLElement} target
 */


function clearHelpTarget(el) {
  el.removeClass('active');
  el.find('[data-help-content]').html();
  el.find('[data-help-action-button]').hide();
}
/**
 * Determines which action to perform given an action button click.
 *
 * @param {string} trigger
 * @param {HTMLElement} el
 */


function handleActionButtonClick(trigger, el) {
  switch (trigger) {
    case 'resend-email':
      resendVerificationEmail();
      break;

    default:
      clearHelpTarget(el.parents('[data-help]'));
  }
}
/**
 * Builds function that is the submit handler for the login action.
 *
 * @param finalCallback function that is executed if a login succeeds (can pop up other modals, for instance)
 */


function loginAction(finalCallBack) {
  return function (e) {
    e.preventDefault();
    $('.error').empty().hide();
    var $this = $(this);
    var username = $this.find('[name="username"]').val();
    var password = $this.find('[name="password"]').val();
    var recaptchaToken = $this.find('[name="g-recaptcha-response"]').val();
    toggleLoadingState(true);

    if (!username || !password) {
      if (!username) {
        showInlineError('username', $this);
      } else {
        removeInlineError('username', $this);
      }

      if (!password) {
        showInlineError('password', $this);
      } else {
        removeInlineError('password', $this);
      }

      toggleLoadingState(false);
      return;
    } // Inputs are hidden during logged-in state, so they retain their values,
    // don't save the password.


    $("[data-action='login'] input[name='password']").val("");
    authorizeUser(username, password, recaptchaToken, function (error, userData) {
      if (error) {
        if (error.type == 'inline') {
          showInlineError('form', $this, error.message);
        } else if (error.type == 'captcha-required') {
          showCaptcha();
          showError('Please sign in with added security');
        } else {
          handleReturnedError(error);
        }

        toggleLoadingState(false);
        return;
      }

      if (!userData.sessionToken) {
        showError('Error when attempting to sign in. Please try again.');
        toggleLoadingState(false);
        return;
      }

      var cookieData = {
        id: userData.punterId,
        username: username,
        isNyraBetsUser: isNyraBetsUser(userData.punterClassificationName),
        name: null
      }; // Clear any previously set cookies.

      setCookie('jwt', 'clear', true);
      setCookie('punter', 'clear', true);
      var jsonWebTokenRequest = getJsonWebToken(userData.sessionToken).then(function (response) {
        // Successful web token request.
        if (response && response.returnCode == 0 && response.jSONWebToken) {
          setCookie('jwt', response.jSONWebToken);
        } else {
          return $.Deferred().rejectWith(this, ['invalid response']);
        }
      }).then(null, function (error) {
        // Because the JSON Web Token is not required functionality,
        // we don't alert the user to the error here. If they made it
        // this far, they were actually logged in via a session token.
        // Send a successful deferred to trick the $.when() call.
        return $.Deferred().resolveWith(this, ['dummy deferred']);
      });

      var errorMessageOnDetailFailure = function errorMessageOnDetailFailure(error) {
        if (error) {
          showError('Error when attempting to sign in. Please try again.');
          toggleLoadingState(false); // Force a "logout" to clear cookies and reset state.

          logout();
        }
      };

      var punterDetailsRequest = getPunterDetails(userData.sessionToken).then(function (response) {
        // Successful punter details request.
        if (response && response.returnCode == 0 && response.name) {
          // Add the punter name to the cookie data.
          cookieData.name = response.name.split('\n')[0] || ''; // Add email to cookie if present. We need this in order to
          // sync Salesforce data.

          if (response.email) {
            cookieData.email = response.email;
          }

          cookieData.handicappingContestInfoJWT = getHandicappingContestInfoFromIAPIResponse(response); // Add the punter name to the page.

          $('.loggedin .name').text(cookieData.name); // Set up video.

          if ($('.multichannel-video-player').length) {
            $('#login-splash, #nyrabets-login-splash').hide();
            $('.multichannel-video-player').show();
            selectDefaultStream();
          } // Set cookie with user details.
          // Toggle view state.


          toggleLoggedInViewState(); //close any modals after successful login

          $.modal.close();
        }
      }).then(null, function (error) {
        errorMessageOnDetailFailure(error);
      });
      var punterNyraAccountNumberRequest = getNyraAccountNumber(userData.sessionToken).then(function (response) {
        if (response && response.returnCode == 0 && response.value) {
          cookieData.nyraAccountNumber = response.value;
        }
      }).then(null, function (error) {
        errorMessageOnDetailFailure(error);
      }); // Make sure all requests succeed. If we cared about failures,
      // we'd need to add a .then(null, function) handler, but any
      // failures are currently handled in getPunterDetails() and
      // failures in getJsonWebToken() are silently ignored.

      $.when(jsonWebTokenRequest, punterNyraAccountNumberRequest, punterDetailsRequest).then(function (jsonWebTokenResponse, punterNyraAccountNumberRequest, punterDetailsRequest) {
        setLoginCookie(cookieData);

        if (finalCallBack) {
          finalCallBack();
        }
      });
    });
  };
}

function redirectUserAfterLogin() {
  var urlVars = getUrlVars();
  var onHandicappingPage = $("[data-contest-type='online']").length;

  if (onHandicappingPage && handicappingClickType == 'online') {
    if (isEligibleToPlayOnlineGameOrShowModals()) {
      var link = getHandicappingForwardURL(handicappingOnlineGameURLBase);
      window.location = link;
    }
  } else if (urlVars && urlVars.redirect) {
    switch (urlVars.redirect) {
      case 'belmontstakes':
        top.location = 'https://www.belmontstakes.com/racing/live';
        break;

      default:
        // Just hope it's a URL.
        window.location = decodeURIComponent(urlVars.redirect);
        break;
    }
  } else {
    location.reload();
  }
}
/**
 * Actions to take on page load.
 */


$(document).ready(function () {
  toggleLoggedInViewState();
  setupRequireUserToLogin();
  addJsonWebTokenToUrls();
  setupRequireUserToLoginToForHandicappingChallenge();
  $("[data-contest-type='onsite']").on('click', function (event) {
    var link = getHandicappingForwardURL($(event.currentTarget).data("href"));
    window.location = link;
  });
  $('[data-action="login"]').submit(loginAction(redirectUserAfterLogin));
  $('.logout').click(function () {
    logout();
  });
  $('[data-help-target]').click(function () {
    activateHelpTarget($(this).data('help-target'));
  });
  $('[data-help-action-button="close"], [data-help-close]', '[data-help]').click(function () {
    clearHelpTarget($(this).parents("[data-help]"));
  });
  $('[data-help-action-button]').click(function () {
    handleActionButtonClick($(this).data('help-action-button'), $(this));
  });
  deleteOldCookies();
});

function addProperlyEncodedSessionTokenToRequestString(request, sessionToken, tokenToReplace) {
  var requestString = "request=" + escape(JSON.stringify(request));
  requestString = requestString.replace("XXXSESSIONTOKENXXX", encodeURIComponent(sessionToken));
  return requestString;
}

function trackOutboundClicks(container) {
  // var eventType = ("ontouchstart" in window) ? "click" : "mousedown";
  // Switch to using 'click' events. Not sure why 'mousedown' was
  // used previously, but it interferes with other click events.
  var eventType = 'click';
  $(container).on(eventType, function (event) {
    var eventTarget = event.target;
    var fevoCheck = $(eventTarget).data("fevo-offer-id");

    if (typeof fevoCheck === undefined) {
      event.stopPropagation();
      var el = event.srcElement || event.target;

      if (!$(el).is('a')) {
        var closestA = $(el).closest('a');
        if (closestA.length) el = closestA[0];
      }

      window.redirectUrl = el.href;
      if (typeof window.redirectUrl == "undefined") return;
      var target = $(el).attr('target');
      var hash = window.redirectUrl.split('#')[1];
      var source = '4810862';
      var type = 'nyrab0';
      var cat;

      if (/nyrabets.com/.test(window.redirectUrl) && hash == "sign-up-bonus") {
        cat = 'signu00';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "daily-giveaway") {
        cat = 'daily0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "promo-calendar") {
        cat = 'promo0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "bankroll-builder") {
        cat = 'bankr0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "who-we-are") {
        cat = 'whowe0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "benefits") {
        cat = 'benef0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "points-program") {
        cat = 'point0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "features") {
        cat = 'featu0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "handicapping-101") {
        cat = 'handi0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "faqs") {
        cat = 'faq-i0';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "hd-video") {
        cat = 'handi00';
      } else if (/nyrabets.com/.test(window.redirectUrl) && hash == "register") {
        cat = 'signu0';
      } else if (/nyrabets.com/.test(window.redirectUrl)) {
        cat = 'nyra-0';
      } else {
        return;
      }

      var newWindow = target && !target.match(/^_(self|parent|top)$/i) || event.ctrlKey || event.shiftKey || event.metaKey || event.which == 2;

      if (newWindow) {
        // No need for a hitCallback because JS will continue to operate after the window opens
        createFloodlightTag(source, type, cat, null);
        return true;
      } else {
        // Prevent standard click, track then open
        if (event.preventDefault) event.preventDefault();else event.returnValue = false; // JS execution aborts if we leave the page, so delay the link click with hitCallback

        createFloodlightTag(source, type, cat, hitBack); // Run hitCallback again if DC takes longer than 1 second

        setTimeout(hitBack, 1000);
      }

      return;
    }
  });
}

function createFloodlightTag(source, type, cat, callback) {
  var axel = Math.random() + '';
  var a = axel * 10000000000000000;
  var tagUrl = "https://" + source + ".fls.doubleclick.net/activityi;src=" + source + ";type=" + type + ";cat=" + cat + ";dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=" + a + "?";
  var tag = document.createElement('iframe');
  tag.setAttribute('src', tagUrl);
  tag.height = '1';
  tag.width = '1';
  tag.frameborder = '0';
  if (callback) tag.onload = callback;
  document.body.appendChild(tag);
}

var hbrun = false; // tracker has not yet run
// HitCallback to open link in same window after tracker

var hitBack = function hitBack() {
  // run once only
  if (hbrun) return;
  hbrun = true;
  window.location.href = window.redirectUrl;
};

$(document).ready(function () {
  trackOutboundClicks(document.body);
});

(function (global) {
  "use strict";
  /* Set up a RequestAnimationFrame shim so we can animate efficiently FOR
   * GREAT JUSTICE. */

  var requestInterval, cancelInterval;

  (function () {
    var raf = global.requestAnimationFrame || global.webkitRequestAnimationFrame || global.mozRequestAnimationFrame || global.oRequestAnimationFrame || global.msRequestAnimationFrame,
        caf = global.cancelAnimationFrame || global.webkitCancelAnimationFrame || global.mozCancelAnimationFrame || global.oCancelAnimationFrame || global.msCancelAnimationFrame;

    if (raf && caf) {
      requestInterval = function requestInterval(fn, delay) {
        var handle = {
          value: null
        };

        function loop() {
          handle.value = raf(loop);
          fn();
        }

        loop();
        return handle;
      };

      cancelInterval = function cancelInterval(handle) {
        caf(handle.value);
      };
    } else {
      requestInterval = setInterval;
      cancelInterval = clearInterval;
    }
  })();
  /* Catmull-rom spline stuffs. */

  /*
  function upsample(n, spline) {
    var polyline = [],
        len = spline.length,
        bx  = spline[0],
        by  = spline[1],
        cx  = spline[2],
        cy  = spline[3],
        dx  = spline[4],
        dy  = spline[5],
        i, j, ax, ay, px, qx, rx, sx, py, qy, ry, sy, t;
     for(i = 6; i !== spline.length; i += 2) {
      ax = bx;
      bx = cx;
      cx = dx;
      dx = spline[i    ];
      px = -0.5 * ax + 1.5 * bx - 1.5 * cx + 0.5 * dx;
      qx =        ax - 2.5 * bx + 2.0 * cx - 0.5 * dx;
      rx = -0.5 * ax            + 0.5 * cx           ;
      sx =                   bx                      ;
       ay = by;
      by = cy;
      cy = dy;
      dy = spline[i + 1];
      py = -0.5 * ay + 1.5 * by - 1.5 * cy + 0.5 * dy;
      qy =        ay - 2.5 * by + 2.0 * cy - 0.5 * dy;
      ry = -0.5 * ay            + 0.5 * cy           ;
      sy =                   by                      ;
       for(j = 0; j !== n; ++j) {
        t = j / n;
         polyline.push(
          ((px * t + qx) * t + rx) * t + sx,
          ((py * t + qy) * t + ry) * t + sy
        );
      }
    }
     polyline.push(
      px + qx + rx + sx,
      py + qy + ry + sy
    );
     return polyline;
  }
   function downsample(n, polyline) {
    var len = 0,
        i, dx, dy;
     for(i = 2; i !== polyline.length; i += 2) {
      dx = polyline[i    ] - polyline[i - 2];
      dy = polyline[i + 1] - polyline[i - 1];
      len += Math.sqrt(dx * dx + dy * dy);
    }
     len /= n;
     var small = [],
        target = len,
        min = 0,
        max, t;
     small.push(polyline[0], polyline[1]);
     for(i = 2; i !== polyline.length; i += 2) {
      dx = polyline[i    ] - polyline[i - 2];
      dy = polyline[i + 1] - polyline[i - 1];
      max = min + Math.sqrt(dx * dx + dy * dy);
       if(max > target) {
        t = (target - min) / (max - min);
         small.push(
          polyline[i - 2] + dx * t,
          polyline[i - 1] + dy * t
        );
         target += len;
      }
       min = max;
    }
     small.push(polyline[polyline.length - 2], polyline[polyline.length - 1]);
     return small;
  }
  */

  /* Define skycon things. */

  /* FIXME: I'm *really really* sorry that this code is so gross. Really, I am.
   * I'll try to clean it up eventually! Promise! */


  var KEYFRAME = 500,
      STROKE = 0.08,
      TAU = 2.0 * Math.PI,
      TWO_OVER_SQRT_2 = 2.0 / Math.sqrt(2);

  function circle(ctx, x, y, r) {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, TAU, false);
    ctx.fill();
  }

  function line(ctx, ax, ay, bx, by) {
    ctx.beginPath();
    ctx.moveTo(ax, ay);
    ctx.lineTo(bx, by);
    ctx.stroke();
  }

  function puff(ctx, t, cx, cy, rx, ry, rmin, rmax) {
    var c = Math.cos(t * TAU),
        s = Math.sin(t * TAU);
    rmax -= rmin;
    circle(ctx, cx - s * rx, cy + c * ry + rmax * 0.5, rmin + (1 - c * 0.5) * rmax);
  }

  function puffs(ctx, t, cx, cy, rx, ry, rmin, rmax) {
    var i;

    for (i = 5; i--;) {
      puff(ctx, t + i / 5, cx, cy, rx, ry, rmin, rmax);
    }
  }

  function cloud(ctx, t, cx, cy, cw, s, color) {
    t /= 30000;
    var a = cw * 0.21,
        b = cw * 0.12,
        c = cw * 0.24,
        d = cw * 0.28;
    ctx.fillStyle = color;
    puffs(ctx, t, cx, cy, a, b, c, d);
    ctx.globalCompositeOperation = 'destination-out';
    puffs(ctx, t, cx, cy, a, b, c - s, d - s);
    ctx.globalCompositeOperation = 'source-over';
  }

  function sun(ctx, t, cx, cy, cw, s, color) {
    t /= 120000;
    var a = cw * 0.25 - s * 0.5,
        b = cw * 0.32 + s * 0.5,
        c = cw * 0.50 - s * 0.5,
        i,
        p,
        cos,
        sin;
    ctx.strokeStyle = color;
    ctx.lineWidth = s;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.beginPath();
    ctx.arc(cx, cy, a, 0, TAU, false);
    ctx.stroke();

    for (i = 8; i--;) {
      p = (t + i / 8) * TAU;
      cos = Math.cos(p);
      sin = Math.sin(p);
      line(ctx, cx + cos * b, cy + sin * b, cx + cos * c, cy + sin * c);
    }
  }

  function moon(ctx, t, cx, cy, cw, s, color) {
    t /= 15000;
    var a = cw * 0.29 - s * 0.5,
        b = cw * 0.05,
        c = Math.cos(t * TAU),
        p = c * TAU / -16;
    ctx.strokeStyle = color;
    ctx.lineWidth = s;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    cx += c * b;
    ctx.beginPath();
    ctx.arc(cx, cy, a, p + TAU / 8, p + TAU * 7 / 8, false);
    ctx.arc(cx + Math.cos(p) * a * TWO_OVER_SQRT_2, cy + Math.sin(p) * a * TWO_OVER_SQRT_2, a, p + TAU * 5 / 8, p + TAU * 3 / 8, true);
    ctx.closePath();
    ctx.stroke();
  }

  function rain(ctx, t, cx, cy, cw, s, color) {
    t /= 1350;
    var a = cw * 0.16,
        b = TAU * 11 / 12,
        c = TAU * 7 / 12,
        i,
        p,
        x,
        y;
    ctx.fillStyle = color;

    for (i = 4; i--;) {
      p = (t + i / 4) % 1;
      x = cx + (i - 1.5) / 1.5 * (i === 1 || i === 2 ? -1 : 1) * a;
      y = cy + p * p * cw;
      ctx.beginPath();
      ctx.moveTo(x, y - s * 1.5);
      ctx.arc(x, y, s * 0.75, b, c, false);
      ctx.fill();
    }
  }

  function sleet(ctx, t, cx, cy, cw, s, color) {
    t /= 750;
    var a = cw * 0.1875,
        b = TAU * 11 / 12,
        c = TAU * 7 / 12,
        i,
        p,
        x,
        y;
    ctx.strokeStyle = color;
    ctx.lineWidth = s * 0.5;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    for (i = 4; i--;) {
      p = (t + i / 4) % 1;
      x = Math.floor(cx + (i - 1.5) / 1.5 * (i === 1 || i === 2 ? -1 : 1) * a) + 0.5;
      y = cy + p * cw;
      line(ctx, x, y - s * 1.5, x, y + s * 1.5);
    }
  }

  function snow(ctx, t, cx, cy, cw, s, color) {
    t /= 3000;
    var a = cw * 0.16,
        b = s * 0.75,
        u = t * TAU * 0.7,
        ux = Math.cos(u) * b,
        uy = Math.sin(u) * b,
        v = u + TAU / 3,
        vx = Math.cos(v) * b,
        vy = Math.sin(v) * b,
        w = u + TAU * 2 / 3,
        wx = Math.cos(w) * b,
        wy = Math.sin(w) * b,
        i,
        p,
        x,
        y;
    ctx.strokeStyle = color;
    ctx.lineWidth = s * 0.5;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    for (i = 4; i--;) {
      p = (t + i / 4) % 1;
      x = cx + Math.sin((p + i / 4) * TAU) * a;
      y = cy + p * cw;
      line(ctx, x - ux, y - uy, x + ux, y + uy);
      line(ctx, x - vx, y - vy, x + vx, y + vy);
      line(ctx, x - wx, y - wy, x + wx, y + wy);
    }
  }

  function fogbank(ctx, t, cx, cy, cw, s, color) {
    t /= 30000;
    var a = cw * 0.21,
        b = cw * 0.06,
        c = cw * 0.21,
        d = cw * 0.28;
    ctx.fillStyle = color;
    puffs(ctx, t, cx, cy, a, b, c, d);
    ctx.globalCompositeOperation = 'destination-out';
    puffs(ctx, t, cx, cy, a, b, c - s, d - s);
    ctx.globalCompositeOperation = 'source-over';
  }
  /*
  var WIND_PATHS = [
        downsample(63, upsample(8, [
          -1.00, -0.28,
          -0.75, -0.18,
          -0.50,  0.12,
          -0.20,  0.12,
          -0.04, -0.04,
          -0.07, -0.18,
          -0.19, -0.18,
          -0.23, -0.05,
          -0.12,  0.11,
           0.02,  0.16,
           0.20,  0.15,
           0.50,  0.07,
           0.75,  0.18,
           1.00,  0.28
        ])),
        downsample(31, upsample(16, [
          -1.00, -0.10,
          -0.75,  0.00,
          -0.50,  0.10,
          -0.25,  0.14,
           0.00,  0.10,
           0.25,  0.00,
           0.50, -0.10,
           0.75, -0.14,
           1.00, -0.10
        ]))
      ];
  */


  var WIND_PATHS = [[-0.7500, -0.1800, -0.7219, -0.1527, -0.6971, -0.1225, -0.6739, -0.0910, -0.6516, -0.0588, -0.6298, -0.0262, -0.6083, 0.0065, -0.5868, 0.0396, -0.5643, 0.0731, -0.5372, 0.1041, -0.5033, 0.1259, -0.4662, 0.1406, -0.4275, 0.1493, -0.3881, 0.1530, -0.3487, 0.1526, -0.3095, 0.1488, -0.2708, 0.1421, -0.2319, 0.1342, -0.1943, 0.1217, -0.1600, 0.1025, -0.1290, 0.0785, -0.1012, 0.0509, -0.0764, 0.0206, -0.0547, -0.0120, -0.0378, -0.0472, -0.0324, -0.0857, -0.0389, -0.1241, -0.0546, -0.1599, -0.0814, -0.1876, -0.1193, -0.1964, -0.1582, -0.1935, -0.1931, -0.1769, -0.2157, -0.1453, -0.2290, -0.1085, -0.2327, -0.0697, -0.2240, -0.0317, -0.2064, 0.0033, -0.1853, 0.0362, -0.1613, 0.0672, -0.1350, 0.0961, -0.1051, 0.1213, -0.0706, 0.1397, -0.0332, 0.1512, 0.0053, 0.1580, 0.0442, 0.1624, 0.0833, 0.1636, 0.1224, 0.1615, 0.1613, 0.1565, 0.1999, 0.1500, 0.2378, 0.1402, 0.2749, 0.1279, 0.3118, 0.1147, 0.3487, 0.1015, 0.3858, 0.0892, 0.4236, 0.0787, 0.4621, 0.0715, 0.5012, 0.0702, 0.5398, 0.0766, 0.5768, 0.0890, 0.6123, 0.1055, 0.6466, 0.1244, 0.6805, 0.1440, 0.7147, 0.1630, 0.7500, 0.1800], [-0.7500, 0.0000, -0.7033, 0.0195, -0.6569, 0.0399, -0.6104, 0.0600, -0.5634, 0.0789, -0.5155, 0.0954, -0.4667, 0.1089, -0.4174, 0.1206, -0.3676, 0.1299, -0.3174, 0.1365, -0.2669, 0.1398, -0.2162, 0.1391, -0.1658, 0.1347, -0.1157, 0.1271, -0.0661, 0.1169, -0.0170, 0.1046, 0.0316, 0.0903, 0.0791, 0.0728, 0.1259, 0.0534, 0.1723, 0.0331, 0.2188, 0.0129, 0.2656, -0.0064, 0.3122, -0.0263, 0.3586, -0.0466, 0.4052, -0.0665, 0.4525, -0.0847, 0.5007, -0.1002, 0.5497, -0.1130, 0.5991, -0.1240, 0.6491, -0.1325, 0.6994, -0.1380, 0.7500, -0.1400]],
      WIND_OFFSETS = [{
    start: 0.36,
    end: 0.11
  }, {
    start: 0.56,
    end: 0.16
  }];

  function leaf(ctx, t, x, y, cw, s, color) {
    var a = cw / 8,
        b = a / 3,
        c = 2 * b,
        d = t % 1 * TAU,
        e = Math.cos(d),
        f = Math.sin(d);
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.lineWidth = s;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.beginPath();
    ctx.arc(x, y, a, d, d + Math.PI, false);
    ctx.arc(x - b * e, y - b * f, c, d + Math.PI, d, false);
    ctx.arc(x + c * e, y + c * f, b, d + Math.PI, d, true);
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fill();
    ctx.globalCompositeOperation = 'source-over';
    ctx.stroke();
  }

  function swoosh(ctx, t, cx, cy, cw, s, index, total, color) {
    t /= 2500;
    var path = WIND_PATHS[index],
        a = (t + index - WIND_OFFSETS[index].start) % total,
        c = (t + index - WIND_OFFSETS[index].end) % total,
        e = (t + index) % total,
        b,
        d,
        f,
        i;
    ctx.strokeStyle = color;
    ctx.lineWidth = s;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    if (a < 1) {
      ctx.beginPath();
      a *= path.length / 2 - 1;
      b = Math.floor(a);
      a -= b;
      b *= 2;
      b += 2;
      ctx.moveTo(cx + (path[b - 2] * (1 - a) + path[b] * a) * cw, cy + (path[b - 1] * (1 - a) + path[b + 1] * a) * cw);

      if (c < 1) {
        c *= path.length / 2 - 1;
        d = Math.floor(c);
        c -= d;
        d *= 2;
        d += 2;

        for (i = b; i !== d; i += 2) {
          ctx.lineTo(cx + path[i] * cw, cy + path[i + 1] * cw);
        }

        ctx.lineTo(cx + (path[d - 2] * (1 - c) + path[d] * c) * cw, cy + (path[d - 1] * (1 - c) + path[d + 1] * c) * cw);
      } else for (i = b; i !== path.length; i += 2) {
        ctx.lineTo(cx + path[i] * cw, cy + path[i + 1] * cw);
      }

      ctx.stroke();
    } else if (c < 1) {
      ctx.beginPath();
      c *= path.length / 2 - 1;
      d = Math.floor(c);
      c -= d;
      d *= 2;
      d += 2;
      ctx.moveTo(cx + path[0] * cw, cy + path[1] * cw);

      for (i = 2; i !== d; i += 2) {
        ctx.lineTo(cx + path[i] * cw, cy + path[i + 1] * cw);
      }

      ctx.lineTo(cx + (path[d - 2] * (1 - c) + path[d] * c) * cw, cy + (path[d - 1] * (1 - c) + path[d + 1] * c) * cw);
      ctx.stroke();
    }

    if (e < 1) {
      e *= path.length / 2 - 1;
      f = Math.floor(e);
      e -= f;
      f *= 2;
      f += 2;
      leaf(ctx, t, cx + (path[f - 2] * (1 - e) + path[f] * e) * cw, cy + (path[f - 1] * (1 - e) + path[f + 1] * e) * cw, cw, s, color);
    }
  }

  var Skycons = function Skycons(opts) {
    this.list = [];
    this.interval = null;
    this.color = opts && opts.color ? opts.color : "black";
    this.resizeClear = !!(opts && opts.resizeClear);
  };

  Skycons.CLEAR_DAY = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    sun(ctx, t, w * 0.5, h * 0.5, s, s * STROKE, color);
  };

  Skycons.CLEAR_NIGHT = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    moon(ctx, t, w * 0.5, h * 0.5, s, s * STROKE, color);
  };

  Skycons.PARTLY_CLOUDY_DAY = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    sun(ctx, t, w * 0.625, h * 0.375, s * 0.75, s * STROKE, color);
    cloud(ctx, t, w * 0.375, h * 0.625, s * 0.75, s * STROKE, color);
  };

  Skycons.PARTLY_CLOUDY_NIGHT = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    moon(ctx, t, w * 0.667, h * 0.375, s * 0.75, s * STROKE, color);
    cloud(ctx, t, w * 0.375, h * 0.625, s * 0.75, s * STROKE, color);
  };

  Skycons.CLOUDY = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    cloud(ctx, t, w * 0.5, h * 0.5, s, s * STROKE, color);
  };

  Skycons.RAIN = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    rain(ctx, t, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);
    cloud(ctx, t, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);
  };

  Skycons.SLEET = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    sleet(ctx, t, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);
    cloud(ctx, t, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);
  };

  Skycons.SNOW = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    snow(ctx, t, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);
    cloud(ctx, t, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);
  };

  Skycons.WIND = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h);
    swoosh(ctx, t, w * 0.5, h * 0.5, s, s * STROKE, 0, 2, color);
    swoosh(ctx, t, w * 0.5, h * 0.5, s, s * STROKE, 1, 2, color);
  };

  Skycons.FOG = function (ctx, t, color) {
    var w = ctx.canvas.width,
        h = ctx.canvas.height,
        s = Math.min(w, h),
        k = s * STROKE;
    fogbank(ctx, t, w * 0.5, h * 0.32, s * 0.75, k, color);
    t /= 5000;
    var a = Math.cos(t * TAU) * s * 0.02,
        b = Math.cos((t + 0.25) * TAU) * s * 0.02,
        c = Math.cos((t + 0.50) * TAU) * s * 0.02,
        d = Math.cos((t + 0.75) * TAU) * s * 0.02,
        n = h * 0.936,
        e = Math.floor(n - k * 0.5) + 0.5,
        f = Math.floor(n - k * 2.5) + 0.5;
    ctx.strokeStyle = color;
    ctx.lineWidth = k;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    line(ctx, a + w * 0.2 + k * 0.5, e, b + w * 0.8 - k * 0.5, e);
    line(ctx, c + w * 0.2 + k * 0.5, f, d + w * 0.8 - k * 0.5, f);
  };

  Skycons.prototype = {
    _determineDrawingFunction: function _determineDrawingFunction(draw) {
      if (typeof draw === "string") draw = Skycons[draw.toUpperCase().replace(/-/g, "_")] || null;
      return draw;
    },
    add: function add(el, draw) {
      var obj;
      if (typeof el === "string") el = document.getElementById(el); // Does nothing if canvas name doesn't exists

      if (el === null) return;
      draw = this._determineDrawingFunction(draw); // Does nothing if the draw function isn't actually a function

      if (typeof draw !== "function") return;
      obj = {
        element: el,
        context: el.getContext("2d"),
        drawing: draw
      };
      this.list.push(obj);
      this.draw(obj, KEYFRAME);
    },
    set: function set(el, draw) {
      var i;
      if (typeof el === "string") el = document.getElementById(el);

      for (i = this.list.length; i--;) {
        if (this.list[i].element === el) {
          this.list[i].drawing = this._determineDrawingFunction(draw);
          this.draw(this.list[i], KEYFRAME);
          return;
        }
      }

      this.add(el, draw);
    },
    remove: function remove(el) {
      var i;
      if (typeof el === "string") el = document.getElementById(el);

      for (i = this.list.length; i--;) {
        if (this.list[i].element === el) {
          this.list.splice(i, 1);
          return;
        }
      }
    },
    draw: function draw(obj, time) {
      var canvas = obj.context.canvas;
      if (this.resizeClear) canvas.width = canvas.width;else obj.context.clearRect(0, 0, canvas.width, canvas.height);
      obj.drawing(obj.context, time, this.color);
    },
    play: function play() {
      var self = this;
      this.pause();
      this.interval = requestInterval(function () {
        var now = Date.now(),
            i;

        for (i = self.list.length; i--;) {
          self.draw(self.list[i], now);
        }
      }, 1000 / 60);
    },
    pause: function pause() {
      var i;

      if (this.interval) {
        cancelInterval(this.interval);
        this.interval = null;
      }
    }
  };
  global.Skycons = Skycons;
})(this);

$(document).ready(function () {
  if (!$('#weather').length) return;
  var skycons = new Skycons({
    "color": "white"
  });
  var weather = $('#weather').data('weather');
  skycons.add("weather", weather);
  skycons.play();
});

function shouldAddSSOJWT(url) {
  var isToNyraBets = url.indexOf('nyrabets.com') > -1;
  var hasHash = url.indexOf('nyrabets.com/#') > -1;
  var hasRaceHash = url.indexOf('nyrabets.com/#race') > -1;
  var hasWageringHash = url.indexOf('nyrabets.com/#wagering') > -1;
  var hasContestLeaderboard = url.indexOf('nyrabets.com/Contest-Leaderboard') > -1;

  if (!isToNyraBets) {
    return false;
  }

  if (!hasHash) {
    return !hasContestLeaderboard;
  }

  if (hasHash) {
    return !!hasRaceHash || !!hasWageringHash;
  }

  return false;
}